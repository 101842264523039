<form [formGroup]="form" (submit)="saveNew()">
  <mat-card>
    <table>
      <tr>
        <th>Adjective</th>
        <th>Image</th>
        <th>Sound</th>
        <th>Definition</th>
      </tr>
      <tr>
        <td><input type="text" formControlName="word"></td>
        <td><input type="file" (change)="onFileChange('img', $any($event))"></td>
        <td><input type="file" (change)="onFileChange('snd', $any($event))"></td>
        <td><input type="text" formControlName="def"></td>
        <td>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="form.invalid">
            <mat-icon>add</mat-icon>
            Add new
          </button>
        </td>
      </tr>
    </table>
  </mat-card>
  <ng-container *ngFor="let phrase of phrases">
    <mat-accordion>
      <mat-expansion-panel #adjRef>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label class="expansion-title">{{phrase.word}}</label>
          </mat-panel-title>
          <mat-panel-description>
            <label>Click to show forms</label>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <table *ngIf="adjRef.opened">
          <tr class="phrase-row-spacer">
            <td colspan="5"></td>
          </tr>
          <tr class="phrase-row">
            <td>
              <div class="img-container">
                <img *ngIf="phrase.img" [src]="phrase.img" class="phrase-img">
                <div *ngIf="phrase.img" class="image-hover" (click)="imgfile.click();">
                  <div class="hover-text">Edit</div>
                </div>
                <button type="button" *ngIf="!phrase.img" mat-button (click)="imgfile.click();"
                        class="snd-button" matTooltip="Upload sound file">
                  <label class="margin-right-10">Upload image</label>
                  <mat-icon>edit</mat-icon>
                </button>
                <input #imgfile type="file" (change)="editPhrase(phrase, 'img', $any($event))" style="display: none;">
              </div>
            </td>
            <td>
              <div class="flex-div">
                <audio *ngIf="phrase.snd" [src]="phrase.snd" controls></audio>
                <button type="button" mat-button (click)="sndfile.click();" class="snd-button" matTooltip="Upload audio file">
                  <label *ngIf="!phrase.snd" class="margin-right-10">Upload sound</label>
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <input #sndfile type="file" (change)="editPhrase(phrase, 'snd', $any($event))" style="display: none;">
            </td>
            <td colspan="2" style="width: 600px;">
              <mat-form-field class="definition">
                <input matInput value="" [(ngModel)]="phrase.def" [ngModelOptions]="{standalone: true}">
              </mat-form-field>
              <button
                type="button"
                mat-raised-button
                color="primary"
                mat-button
                (click)="editDefinition(phrase)" matTooltip="Save definition">
                <mat-icon>save</mat-icon>
                Save
              </button>
            </td>
            <td><button type="button" (click)="removePhrase(phrase.id)">Remove</button></td>
          </tr>
          <tr>
            <td colspan="5" class="word-forms">
              <app-topic-phrase-forms [phrase]="phrase" (removeLemmaProper)="removeLemmaProper($event)" (removeAddInfo)="removeAddInfo($any($event))" (removeWordForm)="removeWordForm($event)"></app-topic-phrase-forms>
            </td>
          </tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-container>
</form>
