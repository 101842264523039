<form [formGroup]="formGroup" (submit)="saveNew()">
  <mat-card>
    <mat-card-header>
      <mat-card-title class="add-phrase-title">
        Add new phrase
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <table>
        <tr>
          <td colspan="4">
            <div class="word-settings">
              <mat-form-field class="margin-right-10">
                <input matInput value="" formControlName="noun" placeholder="Word">
              </mat-form-field>

              <mat-form-field class="margin-right-10 width-50px">
                <input matInput value="" formControlName="prefix" placeholder="Prefix">
              </mat-form-field>

              <mat-form-field class="margin-right-10 width-50px">
                <input matInput value="" formControlName="suffix" placeholder="Suffix">
              </mat-form-field>

              <div class="plural-container">
                <label class="plural-label">Plural: </label>
                <section>
                  <mat-slide-toggle
                    [ngModelOptions]="{standalone: true}"
                    class="example-margin"
                    [color]="'primary'"
                    [(ngModel)]="plural">
                  </mat-slide-toggle>
                </section>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="flex-div">
              <button
                mat-stroked-button
                color="accent"
                type="button"
                (click)="newImgFile.click();"
                matTooltip="Upload image file">
                {{uploadImgLabel}}
                <mat-icon>backup</mat-icon>
              </button>
            </div>
            <input #newImgFile type="file" (change)="onFileChange('img', $any($event))"
                   style="display: none;">
          </td>
          <td>
            <div class="flex-div">
              <button
                mat-stroked-button
                color="accent"
                type="button"
                (click)="newSndFile.click();"
                matTooltip="Upload audio file">
                {{uploadSndLabel}}
                <mat-icon>backup</mat-icon>
              </button>
            </div>
            <input #newSndFile type="file" (change)="onFileChange('snd', $any($event))"
                   style="display: none;">
          </td>
          <td class="width-60p">
            <mat-form-field class="definition-input">
              <input matInput value="" formControlName="def" placeholder="Definition">
            </mat-form-field>
          </td>

          <td style="width: 6%;">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="formGroup.invalid">
              <mat-icon>add</mat-icon>
              Add new
            </button>
          </td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>

  <div class="progress-spinner" *ngIf="phrases.length === 0">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <ng-container *ngFor="let phrase of phrases | slice:pageMin:pageMax">
    <table class="table-group">
      <tr class="phrase-row-spacer">
        <td colspan="5"></td>
      </tr>
      <tr class="phrase-row">
        <td>
          <div class="img-container">
            <img *ngIf="phrase.img" [src]="phrase.img" class="phrase-img">
            <div class="image-hover" (click)="imgfile.click();">
              <div class="hover-text">Edit</div>
            </div>
            <input
                #imgfile
                type="file"
                (change)="editPhrase(phrase, 'img', $any($event))"
                style="display: none;">
          </div>
        </td>

        <td>
          <div class="flex-div">
            <audio *ngIf="phrase.snd" [src]="phrase.snd" controls></audio>
            <button type="button" mat-button (click)="sndfile.click();" class="snd-button"
                    matTooltip="Upload audio file">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <input
              #sndfile
              type="file"
              (change)="editPhrase(phrase, 'snd', $any($event))"
              style="display: none;">
        </td>

        <td colspan="2" style="width: 600px;">
          <mat-form-field class="definition">
            <input matInput value="" [(ngModel)]="phrase.def" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            type="button"
            (click)="editDefinition(phrase)" matTooltip="Save definition">
            <mat-icon>save</mat-icon>
            Save
          </button>
        </td>

        <td class="phrase-icon-section">
          <button
            mat-icon-button
            color="primary"
            type="button"
            (click)="refreshPhrase(phrase)"
            matTooltip="Refresh word forms from morphodita. This will removes any changes made to this phrase.">
            <mat-icon>refresh</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Remove"
            color="error"
            type="button"
            (click)="removePhrase(phrase.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </table>

    <!-- data -->
    <mat-accordion>
      <mat-expansion-panel #refd>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label class="expansion-title">
              {{ phrase.noun }}
            </label>
          </mat-panel-title>
          <mat-panel-description>
            Click to show forms
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-topic-phrase-forms
          *ngIf="refd.opened"
          [phrase]="phrase"
          (removeLemmaProper)="removeLemmaProper($event)"
          (removeAddInfo)="removeAddInfo($event)"
          (removeWordForm)="removeWordForm($event)">
        </app-topic-phrase-forms>
      </mat-expansion-panel>

      <!-- adjectives -->
      <mat-expansion-panel
        *ngIf="getWordType(phrase) === 'noun'"
        #refAdj>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label class="expansion-title">List of adjectives</label>
          </mat-panel-title>
          <mat-panel-description>
            Click to show adjectives
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-topic-phrase-adjectives
          *ngIf="refAdj.opened"
          [parentPhrase]="phrase"
          [topicPhrase]="topicId + '/' + phrase.id">
        </app-topic-phrase-adjectives>
      </mat-expansion-panel>
    </mat-accordion>

  </ng-container>

  <mat-card class="mt-1" *ngIf="phrases.length > 0">
    <mat-paginator class="paginator" [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageEvent = processEventData($event)">
    </mat-paginator>
  </mat-card>
</form>
