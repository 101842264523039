
<mat-toolbar>
  <span *ngIf="topic">
    {{ topic?.title }}
  </span>

  <span *ngIf="type === 'add'">
    Create new topic
  </span>

  <button
    *ngIf="topic"
    type="button"
    mat-raised-button
    class="__publish-button"
    (click)="publish(topic.id)"
    color="primary">
    <mat-icon>published_with_changes</mat-icon>
    Publish
  </button>
</mat-toolbar>
<br>

<mat-progress-bar *ngIf="generated > 0" mode="determinate" [value]="generated * 50" class="gen-progress-bar"></mat-progress-bar>

<span class="done" *ngIf="generated > 1">Done</span>
<br>

<div class="content">
  <mat-card>
    <h4 mat-card-title>
      <span *ngIf="type === 'add'">Fill the details about the topic</span>
    </h4>

    <form [formGroup]="form" (submit)="saveNewTopic()" class="topic-form">

      <div class="col">
        <mat-form-field>
          <input matInput type="text" formControlName="title" placeholder="Title">
        </mat-form-field>

        <mat-form-field>
          <input matInput type="number" min="1" formControlName="version" placeholder="Version">
        </mat-form-field>

      </div>

      <div class="col">
        <mat-form-field>
          <mat-select formControlName="color" style="margin-left: 12px;" placeholder="Color in the application">
            <mat-option *ngFor="let color of topicColors" [value]="color" >
              <div class="palette-color" [ngStyle]="{'background': color}">{{color}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-stroked-button color="accent" type="button"
          class="mb-1"
          (click)="newIconFile.click();"
          matTooltip="Upload image file">
          {{newIconLabel}}
          <mat-icon>backup</mat-icon>
        </button>

        <input #newIconFile type="file" (change)="onNewIconSelect($any($event))" style="display: none;">

      </div>

      <div class="col">
        <img
          *ngIf="topic?.icon"
          [src]="topic?.icon"
          [alt]="topic?.title"
          width="120">
      </div>

      <div class="col col--btns">
        <button
          *ngIf="type === 'add'"
          mat-stroked-button
          type="submit"
          [disabled]="form.invalid"
          color="primary">
          <mat-icon>add</mat-icon>
          Add new
        </button>

        <button
          *ngIf="type === 'edit'"
          mat-raised-button
          type="button"
          (click)="saveTopic(topic)"
          color="primary">
          <mat-icon>save</mat-icon>
          Save basic topic settings
        </button>

      </div>
    </form>

    <button
      *ngIf="type === 'edit'"
      class="delete-button"
      matTooltip="Remove this topic"
      mat-stroked-button
      type="button"
      (click)="remove(topic.id, topic.icon ? true : false)"
      color="error">
      <mat-icon>delete</mat-icon>
      Delete this topic
    </button>
  </mat-card>
</div>
