import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {child, get, off, onValue, orderByChild, push, query, remove, set, update} from '@angular/fire/database';
import {deleteObject, getDownloadURL, ref as storage_ref, uploadBytes} from '@angular/fire/storage';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {appColors} from '../../constants/app.constants';
import {FirebaseService} from '../../firebase.service';
import {NumberEnum} from '../../generator.constants';
import {GeneratorService} from '../../generator.service';
import {Adjective, DataSnapshot, FirebaseTopic, Phrase, QAs} from '../../generator.types';
import {StorageService} from '../../storage.service';
import {getConfirmedIncorrect} from './topic-settings.utils';

const NEW_ICON_LABEL = 'Upload icon file';

enum SettingType {
  Add = 'add',
  Edit = 'edit',
}

@Component({
  selector: 'app-topic-settings',
  templateUrl: './topic-settings.component.html',
  styleUrls: ['./topic-settings.component.scss'],
})
export class TopicSettingComponent implements OnInit, OnDestroy {
  @Input()
  set topicId(v: string) {
    if (v) {
      this._topicId = v;

      if (Object.keys(this.topicList).length) {
        this.topic = this.topicList[v];
        this.form.patchValue(this.topic as any);
      }
      this.generated = 0;
    }
  }
  get topicId() {
    return this._topicId;
  }

  @Input()
  type: string = SettingType.Edit;

  newIconLabel = NEW_ICON_LABEL;
  editIconLabel = NEW_ICON_LABEL;

  dbReference = child(this.firebaseService.database(), 'topics');
  storageReference = this.firebaseService.storage('topics');
  generated = 0;
  step = 0;
  editTopicId: string;
  topicColors = appColors;
  topic: FirebaseTopic;
  topicList: Record<string, FirebaseTopic> = {};

  _topics$ = new BehaviorSubject<FirebaseTopic[]>([]);
  topics$ = this._topics$.asObservable();

  form = this.formbuilder.group({
    title: ['', Validators.required],
    version: ['1', Validators.required],
    color: [null, Validators.required],
    package: [null],
    icon: [null],
  });

  private _topicId: string;

  constructor(
    private firebaseService: FirebaseService,
    private formbuilder: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private generatorService: GeneratorService,
  ) { }

  ngOnInit() {
    const dbRefOrdered = query(this.dbReference, orderByChild('order'));
    onValue(dbRefOrdered, snapshot => {
      snapshot.forEach(snapshotChild => {
        const value = snapshotChild.val();
        const color = value.color ? value.color : this.topicColors[0];

        this.topicList[snapshotChild.key] = {id: snapshotChild.key, ...value, color};
      });

      if (this.type === SettingType.Edit) {
        this.topic = this.topicList[this.topicId];
        this.form.patchValue(this.topic as any);
      }
    });
  }

  ngOnDestroy() {
    this.generated = 0;
    off(this.dbReference);
  }

  async setPackage(topic: FirebaseTopic) {
    await set(child(this.dbReference, topic.id + '/package'), topic.package);
  }

  async saveNewTopic() {
    const formGroup = this.form;
    const newTopicRef = push(this.dbReference);
    const value = formGroup.value;

    await set(newTopicRef, {
      title: value.title,
      version: value.version,
      color: value.color,
      order: 0,
      icon: '',
    });

    if (value.icon) {
      await Promise.all(['icon'].map(
        async key => {
          const storageRef = storage_ref(storage_ref(this.storageReference, newTopicRef.key), key);
          const snapshot = await uploadBytes(storageRef, value.icon);
          const downloadURL = await getDownloadURL(snapshot.ref);
          update(newTopicRef, {[key]: downloadURL});
          this.newIconLabel = NEW_ICON_LABEL;
        },
      ));
    }

    if (value.package) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      await set(child(this.dbReference, newTopicRef.key + '/package'), value.package);
    }

    formGroup.reset();
    formGroup.markAsPristine();

    setTimeout(() => {
      this.router.navigate(['topic', newTopicRef.key]);
    }, 500);
  }

  async remove(id: string, hasIcon: boolean) {
    if (confirm('Are you sure')) {
      const actions = [
        this.storageService.removePhrasesObjectsByTopic(id),
        remove(child(this.dbReference, id)),
        this.removeAllByTopicId(id),
      ];
      if (hasIcon) {
        actions.push(deleteObject(storage_ref(this.storageReference, id + '/icon')));
      }
      await Promise.all(actions);

      setTimeout(() => {
        this.router.navigateByUrl('/');
      }, 500);
    }
  }

  async removeAllByTopicId(topicId: string) {
    await Promise.all([
      remove(this.firebaseService.database('topicPhrases/' + topicId)),
      remove(this.firebaseService.database('topicPhraseAdjectives/' + topicId)),
      remove(this.firebaseService.database('topicSentences/' + topicId)),
      remove(this.firebaseService.database('topicQuestions/' + topicId)),
    ]);
  }

  onNewIconSelect(event: Event & {target: HTMLInputElement}) {
    if (event.target.files) {
      this.form.patchValue({['icon']: event.target.files[0]});
      this.newIconLabel = event.target.files[0].name;
    }
  }

  onEditIconSelect(event: Event & {target: HTMLInputElement}) {
    if (event.target.files) {
      this.form.patchValue({['icon']: event.target.files[0]});
      this.editIconLabel = event.target.files[0].name;
    }
  }

  async saveTopic(topic: FirebaseTopic) {
    this.editTopicId = null;
    const formGroup = this.form;
    const topicRef = child(this.dbReference, topic.id);
    const value = formGroup.value;
    const topicVersion = topic.version as unknown as string;
    const topicColor = topic.version as unknown as any;

    if (value.title !== topic.title) {
      await set(child(topicRef, 'title'), value.title);
    }

    if (value.version !== topicVersion) {
      await set(child(topicRef, 'version'), value.version);
    }

    if (value.color !== topicColor) {
      await set(child(topicRef, 'color'), value.color);
    }

    // TODO: think about changing the name of upload formControl to avoid interference with icon
    if (value.icon && typeof value.icon !== 'string') {
      await Promise.all(['icon'].map(
        async key => {
          const storageRef = storage_ref(storage_ref(this.storageReference, topicRef.key), key);
          const snapshot = await uploadBytes(storageRef, value.icon);
          const downloadURL = await getDownloadURL(snapshot.ref);
          update(topicRef, {[key]: downloadURL});
          this.editIconLabel = NEW_ICON_LABEL;
        },
      ));
    }
  }

  publish(topicId: string) {
    this.generated = 0.1;
    this.generateSentences(topicId);
    this.generatePhrases(topicId);
  }

  async generatePhrases(topicId: string) {
    const [ phrases, adjectives ]: DataSnapshot[] = await Promise.all([
      get(this.firebaseService.database('topicPhrases/' + topicId)),
      get(this.firebaseService.database('topicPhraseAdjectives/' + topicId)),
    ]);
    const result: any = {};
    const wordDb: Phrase[] = [];

    phrases.forEach(phrase => {
      const val: Phrase = phrase.val();
      const suffix: string = val.suffix ? val.suffix : null;
      const prefix: string = val.prefix ? val.prefix : null;
      result[phrase.key] = {noun: val.noun, img: val.img, snd: val.snd, def: val.def, suffix, prefix};
      wordDb.push(({id: phrase.key, ...phrase.val()}) as Phrase);
    });

    const adjectivesArray: Adjective[] = [];
    adjectives.forEach(phrase => {
      phrase.forEach(adjective => {
        adjectivesArray.push(({phraseId: phrase.key, id: adjective.key, ...adjective.val()}) as Adjective);
      });
    });

    const compiledWords = this.generatorService.compileWords([...wordDb, ...adjectivesArray]);
    adjectives.forEach(phrase => phrase.forEach(adjective => {
      const val: Adjective = adjective.val();
      let noun: string[] = [];

      (val.wordForms || []).forEach(form => {
        const isPlural = this.generatorService.isPlural(form.items);
        noun = this.generatorService.morphNounAdjective(compiledWords,
          {
            adjective: form.lemmaProper,
            noun: phrases.child(phrase.key).val().noun,
          },
          1,
          isPlural ? NumberEnum.Plural : NumberEnum.Singular, undefined, true,
        );
      });

      if (noun[0] && noun[0].includes(' ')) {
        result[adjective.key] = {
          noun: noun[0],
          img: val.img,
          snd: val.snd,
          def: val.def,
        };
      }
    }));

    await set(child(this.firebaseService.database('generatedTopics/phrases'), topicId), result);

    this.generated++;
  }

  async generateSentences(topicId: string) {
    const [ sentences, questions ]: DataSnapshot[] = await Promise.all([
      get(this.firebaseService.database('topicSentences/' + topicId)),
      get(this.firebaseService.database('topicQuestions/' + topicId)),
    ]);
    const statements: Record<string, any> = {};

    sentences.forEach(id => {
      // publish only those that are confirmed in the step of building
      const generated = id.val().generated as QAs[];
      const publishReady = getConfirmedIncorrect(generated);
      statements[id.key] = publishReady;
    });

    questions.forEach(id => {
      const generated = id.val().generated as QAs[];
      const publishReady = getConfirmedIncorrect(generated);
      statements[id.key] = publishReady;
    });

    await set(this.firebaseService.database('generatedTopics/sentences/' + topicId), statements);

    this.generated++;
  }
}
