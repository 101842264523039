import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, OnInit} from '@angular/core';
import {child, onValue, orderByChild, query, update} from '@angular/fire/database';
import {BehaviorSubject} from 'rxjs';

import {appColors} from '../../constants/app.constants';
import {FirebaseService} from '../../firebase.service';
import {FirebaseTopic} from '../../generator.types';

@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.scss'],
})
export class TopicListComponent implements OnInit {

  dbReference = child(this.firebaseService.database(), 'topics');
  topicColors = appColors;
  _topics$ = new BehaviorSubject<FirebaseTopic[]>([]);
  topics$ = this._topics$.asObservable();

  dragAllowed = false;

  constructor(
    private firebaseService: FirebaseService,
  ) { }

 ngOnInit() {
    const dbRefOrdered = query(this.dbReference, orderByChild('order'));
    onValue(dbRefOrdered, snapshot => {
      const topics: FirebaseTopic[] = [];
      snapshot.forEach(snapshotChild => {
        const value = snapshotChild.val();
        const color = value.color ? value.color : this.topicColors[0];
        topics.push({id: snapshotChild.key, ...value, color});
      });
      this._topics$.next(topics);
    });
  }

  dropTopic(event: CdkDragDrop<FirebaseTopic[]>) {
    const list = this._topics$.value;
    const prevIndex = list.findIndex(d => d === event.item.data);
    moveItemInArray(list, prevIndex, event.currentIndex);

    // TODO(ilja): that commented algorithm could be used after next release,
    // because it updates only necessary entries, but for the next release,
    // I keep the code updating everything for the migration purpose
    // let i, count;
    // if (prevIndex < event.currentIndex) {
    //  i = prevIndex;
    //  count = event.currentIndex + 1;
    // } else {
    //  i = event.currentIndex;
    //  count = prevIndex + 1;
    // }
    let i = 0;
    const count = list.length;

    while (i < count) {
      list[i].order = i;
      update(child(this.dbReference, list[i].id), {order: i});
      i++;
    }

    this._topics$.next(list);
  }

}
