import {Component, ViewChild} from '@angular/core';
import {MatTabGroup} from '@angular/material/tabs';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
@Component({
  selector: 'app-topic-page',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
})
export class TopicPageComponent {
  selectedTopicId: string;
  destroy = new Subject();
  step = 0;
  activeTab = 0;

  @ViewChild('tabsRef') tabs: MatTabGroup;

  constructor(
    private route: ActivatedRoute,
  ) {
     this.route.params.subscribe(p => {
       if (this.tabs) {
         this.tabs.selectedIndex = 0;
         this.activeTab = 0;
        }
       if (p.id) this.selectedTopicId = p.id;
     });
  }

  setStep(step: number) {
    this.step = step;
  }

  editStatement() {
    this.step = 1;
  }

  onTabChange(evt: number) {
    this.activeTab = evt;
  }
}
