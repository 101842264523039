import {cloneDeep, difference, differenceBy, groupBy, map} from 'lodash';

import {Gender, Grade, NumberEnum, PartOfSpeech, PerfectiveAspect, Person, Tense} from './generator.constants';
import {
  getCase,
  getColloquial,
  getGender,
  getGrade,
  getNegation,
  getNumber,
  getPartOfSpeech,
  getPerson,
  getTense,
  getVariant,
  isUnwantedForm,
} from './generator.helpers';
import {
  Adjective,
  AdjectiveNoun as AdjectiveNounAdverb,
  BasicLexicalGender,
  GroupedWordForms,
  ItemInfo,
  LemmaSequence,
  Lemmatized,
  LexicalCase,
  LexicalGender,
  LexicalGrade,
  LexicalNumber,
  LexicalPerfectiveAspect,
  LexicalPerson,
  LexicalPunctuation,
  LexicalReflexivePronoun,
  LexicalTense,
  LexicalType,
  Morphed,
  MorphoditoGenerator,
  MorphoditoWord,
  Phrase,
  QAs,
  Sentence,
  SentencesResult,
  Statement,
  Topic,
  VerbForms,
  WordDb,
  WordForm,
  WordGenerator,
} from './generator.types';
import {
  combine,
  distinct,
  getHelpingVerb,
  getReflexivePronoun,
  isOrIncludes,
  lemmaSententize,
  objectDistinct,
  sententize,
  sortWordForms,
} from './generator.utils';

export class GeneratorService {

  helpingVerb: Phrase | Adjective;
  firstGenerator: Sentence[];
  reflexivePronoun: Phrase | Adjective;

  constructor() {
    this.helpingVerb = getHelpingVerb();
    this.reflexivePronoun = getReflexivePronoun();
  }

  async requestMorphodita(word: string) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fetch = require('node-fetch');
    const url = `https://lindat.mff.cuni.cz/services/morphodita/api/generate?data=${word}&output=json`;
    return await fetch(url)
      .then((res: { ok: boolean; json: () => MorphoditoGenerator }) => {

        if (!res.ok) {
          throw new Error(`Word ${word} was not found in Morphodita.`);
        }
        return res.json();
      })
      .then((res: MorphoditoGenerator) => {
        let wordForms = res.result[0]
          .filter(form => isUnwantedForm(form.tag))
          .map((w: MorphoditoWord) => this.processWord(w));
        wordForms = sortWordForms(wordForms);
        return this.groupWordForms(wordForms);
      });
  }

  compileWords(phrases: (Phrase | Adjective)[]): WordDb {
    const wordDb: WordDb = {};

    // add helpingVerb and reflexivePronoun to wordDb
    phrases = phrases.concat(this.helpingVerb).concat(this.reflexivePronoun);

    phrases.forEach(phrase => {

      const forms = phrase.wordForms || [];
      forms.forEach(wf => {
        wf.items.forEach(item => {
          item.items.forEach(a => {
            wordDb[a.lemmaRaw] = wordDb[a.lemmaRaw] || [];
            wordDb[a.lemmaRaw] = [...wordDb[a.lemmaRaw], a];
          });
        });
      });
    });
    return wordDb;
  }

  compileSentences(topic: Topic, wordDb: WordDb, preview = false): QAs[] {
    this.firstGenerator = null;
    return [
      ...topic.questions.map(question => this.generateQAs(wordDb, question.answer, topic, preview, question.question)),
      ...topic.sentences.map(sentence => this.generateQAs(wordDb, sentence, topic, preview)),
    ];
  }

  // gets wordForms that satisfies the user input when building a sentence
  morphAdverb(wordDb: WordDb, phrase: string, lexGrade: LexicalGrade, correct: boolean): string[] {
    const forms = wordDb[phrase];

    const chosenForms: WordForm[][] = [];

    chosenForms.push(forms.filter(a => {
      // we do not want to select
      const fixedCondition = a.negation === false && a.colloquial === false;
      const dynamicCondition = a.grade === lexGrade;
      return correct === dynamicCondition && fixedCondition;
    }));

    const res = combine(chosenForms, (a, n) => (
      a.colloquial === n.colloquial
    )).map(i => i.map(f => f.form).join(' '));

    return distinct(res);
  }

  // gets wordForms that satisfies the input, only for nouns & adjectives
  morphNounAdjective(
    wordDb: WordDb,
    phrase: AdjectiveNounAdverb,
    lexCase: LexicalCase,
    lexNumber: LexicalNumber,
    lexGender: LexicalGender,
    correct: boolean,
    lexGrade: LexicalGrade = null,
  ): string[] {
    const chosenForms: WordForm[][] = [];

    for (const part of ([PartOfSpeech.Adjective, PartOfSpeech.Noun] as (keyof AdjectiveNounAdverb)[])) {
      if (!phrase[part]) continue;

      const forms = wordDb[phrase[part]];
      if (forms) {
        chosenForms.push(forms.filter(form => {
          // TODO(john) make grade and negation dependant on definition
          const fixedCondition = (
            form.colloquial === false &&
            form.negation === false &&
            form.type === part &&
            form.number === lexNumber &&
            (form.grade === undefined ||
              (lexGrade === null && form.grade === 1) ||
              (lexGrade && form.grade === lexGrade)
            )
          );

          const dynamicCondition = (
            form.case === lexCase &&
            (lexGender === undefined || form.gender === lexGender)
          );

          return correct === dynamicCondition && fixedCondition;
        }));
      }
    }

    const res = combine(chosenForms, (a, n) => (
      a.case === n.case &&
      a.gender === n.gender &&
      ((!a.grade || !n.grade) || a.grade === n.grade) &&
      a.number === n.number &&
      a.colloquial === n.colloquial
    )).map(i => i.map(f => f.form).join(' '));

    return distinct(res);
  }

  // All forms must be plural!
  isPlural(forms: ItemInfo[]): boolean {
    return forms.every(form =>
      !form.items.some(item => item.number === NumberEnum.Singular) && form.items[0].type !== PartOfSpeech.Adverb
    );
  }

  removeSingularForms(forms: GroupedWordForms[]) {
    forms[0].items.forEach(form => form.items = form.items.filter(item => item.number === NumberEnum.Plural));
  }

  /**
   * Simple glue for suffix and prefix
   */
  wordAffix(word: string, prefix: string, suffix: string): string {
    const wordBuilder: string[] = [];

    if (prefix) wordBuilder.push(prefix);
    wordBuilder.push(word);
    if (suffix) wordBuilder.push(suffix);

    return wordBuilder.join(' ');
  }

  private processWord(word: MorphoditoWord): WordForm {
    return {
      addInfo: this.getAddInfo(word.lemma),
      form: word.form,
      tag: word.tag,
      lemma: this.getLemmaRoot(word.lemma),
      lemmaRaw: word.lemma,
      case: getCase(word.tag),
      gender: getGender(word.tag),
      number: getNumber(word.tag),
      type: getPartOfSpeech(word.tag),
      tense: getTense(word.tag),
      person: getPerson(word.tag),
      colloquial: getColloquial(word.tag),
      negation: getNegation(word.tag),
      grade: getGrade(word.tag),
      variant: getVariant(word.tag),
    };
  }

  private getLemmaRoot(lemma: string) {
    return lemma.replace(/(-|_).*$/, '');
  }

  private getAddInfo(lemma: string) {
    return lemma.indexOf('_') > -1 ? lemma.replace(/^[^_]+_/, '') : '';
  }

  private groupWordForms(wordForms: WordForm[]): GroupedWordForms[] {
    return map(groupBy(wordForms, 'lemma'), (outerItems, lemmaProper) => ({
      lemmaProper,
      items: map(groupBy(outerItems, 'addInfo'), (items, addInfo) => ({addInfo, items})),
    }));
  }

  private generateQAs(
    wordDb: WordDb,
    statement: Statement,
    topic: Topic,
    preview: boolean,
    question?: string,
  ): QAs {
    question = question || '';
    const {correct, incorrect, lemmatized} = this.generateStatements(wordDb, statement, topic, preview);
    return {correct, incorrect, lemmatized, question};
  }

  private generateStatements(
    wordDb: WordDb,
    statements: Statement,
    topic: Topic,
    preview: boolean,
  ) {
    const res: {
      correct: string[];
      incorrect: {
        sentence: string;
        confirmed: boolean;
      }[];
      lemmatized: LemmaSequence[];
    } = {
      correct: [],
      incorrect: [],
      lemmatized: [],
    };

    if (!Array.isArray(statements)) {
      statements = [statements];
    }

    for (const statement of statements) {
      let correct: string[];
      let incorrect: { sentence: string; confirmed: boolean }[];
      let lemmatized: LemmaSequence[];

      if (typeof statement === 'string') {
        // TODO(john) generate incorrect for string-defined correct
        correct = [statement];
        lemmatized = [[statement]];

      } else {
        const correctSentences = this.generateSentences(
          wordDb, statement, topic.phrases, true, preview);
        correct = correctSentences.forms;
        lemmatized = correctSentences.lemmatized;
        incorrect = this.generateSentences(
          wordDb, statement, topic.phrases, false, preview).forms.map(a => {
          return {
            sentence: a,
            confirmed: true,
          };
        });
      }

      res.correct = res.correct.concat(correct);
      res.incorrect = res.incorrect.concat(incorrect);
      res.lemmatized = res.lemmatized.concat(lemmatized);
    }

    res.incorrect = differenceBy(res.incorrect, res.correct.map(a => ({sentence: a, confirmed: true})), 'sentence');

    res.correct = distinct(res.correct);
    res.lemmatized = objectDistinct(res.lemmatized);
    res.incorrect = distinct(res.incorrect);

    return res;
  }

  private generateSentences(
    wordDb: WordDb,
    sentence: Sentence,
    phrases: Phrase[],
    correct: boolean,
    preview = false,
    nested = false,
    withinFirst = false,
  ): SentencesResult {
    if (!Array.isArray(sentence.generator)) {
      sentence.generator = [sentence.generator];
    }
    const nouns = new Set<string>();
    const adverbs = new Set<string>();
    const adjectives = new Set<string>([null]);

    phrases.forEach(p => {
      (p.adjectives || [] as string[]).forEach(i => {
        adjectives.add(i);
      });
    });

    Object.keys(wordDb).forEach(key => {
      if (wordDb[key][0].type === PartOfSpeech.Noun) {
        nouns.add(key);
      }

      if (wordDb[key][0].type === PartOfSpeech.Adverb) {
        adverbs.add(key);
      }
    });

    const allAdjectiveNouns: AdjectiveNounAdverb[] = combine([
      Array.from(adjectives), Array.from(nouns),
    ]).map(i => ({adjective: i[0], noun: i[1]}));

    const segments: string[][] = [];
    const segmentsIncorrectGrammar: string[][] = [];
    const incorrect = sentence.acceptedIncorrect;
    let lemmaSegments: (string | LemmaSequence)[][] = [];
    if (!nested) {
      this.firstGenerator = sentence.generator[0] as Sentence[];
    }

    for (const generator of sentence.generator) {
      let forms: string[] = [];
      let formsIncorrectGrammar: string[] = [];
      let lemmaForms: (string | LemmaSequence)[][] = [];
      let isFirst = false;
      if (generator === this.firstGenerator || withinFirst) {
        isFirst = true;
      }

      if (typeof generator === 'string') {
        forms = [generator];
        lemmaForms.push([generator]);

      } else if (Array.isArray(generator)) {
        const subresult = generator
          .map(s => this.generateSentences(wordDb, s, phrases, correct, preview, true, isFirst))
          .reduce((a, b) => ({
              forms: a.forms.concat(b.forms),
              formsIncorrectGrammar: a.formsIncorrectGrammar.concat(b.formsIncorrectGrammar),
              lemmatized: a.lemmatized.concat(b.lemmatized),
            }), {forms: [], formsIncorrectGrammar: [], lemmatized: []},
          );

        forms = subresult.forms;
        formsIncorrectGrammar = subresult.formsIncorrectGrammar;
        lemmaForms.push(subresult.lemmatized);

      } else if (correct) {
        const generatedForms: Morphed = this.generateForms(wordDb, generator, phrases, true, isFirst);

        if (generatedForms.lemmatized.length > 1) {
          const corrects = cloneDeep(generatedForms.lemmatized);
          for (let i = 0; i < corrects.length; i++) {
            const lemmas: string[] = [corrects[i].correct];
            corrects.forEach(l2 => {
              if (l2.correct !== lemmas[0] && corrects[i].lemma === l2.lemma) lemmas.push(l2.correct);
            });
            generatedForms.lemmatized[i].correct = lemmas.join('/');
          }
        }
        forms = generatedForms.strings;
        lemmaForms = lemmaForms.concat([generatedForms.lemmatized]);

      } else {
        allAdjectiveNouns.forEach(an => {
          if (generator.tense) return;

          const incorrectGenerator = {...generator, phrase: an};
          if (!incorrect || isOrIncludes(incorrect, 'fact')) {
            forms = forms.concat(this.generateForms(
              wordDb, incorrectGenerator, phrases, true, isFirst).strings);
          }
        });

        const incorrectGrammar = this.generateForms(wordDb, generator, phrases, false, isFirst).strings;
        if (!incorrect || isOrIncludes(incorrect, 'grammar')) {
          forms = forms.concat(incorrectGrammar);
        }
        formsIncorrectGrammar = formsIncorrectGrammar.concat(incorrectGrammar);
      }

      // if only factual incorrects are allowed, subtract grammar incorrects from them
      // e.g. adj form of bramborová (polévka) vs. bramborový (guláš) which is factual difference
      // produce different form of bramborový - grammar incorrectness
      if (incorrect && !isOrIncludes(incorrect, 'grammar')) {
        forms = difference(forms, formsIncorrectGrammar);
      }
      segments.push(distinct(forms));
      segmentsIncorrectGrammar.push(distinct(formsIncorrectGrammar));
      lemmaSegments = lemmaSegments.concat(objectDistinct(lemmaForms));
    }

    return {
      forms: this.processSegments(segments, nested, preview, sentence.punctuation),
      formsIncorrectGrammar: this.processSegments(segmentsIncorrectGrammar, nested, preview, sentence.punctuation),
      lemmatized: this.processLemmaSegments(lemmaSegments, nested, preview, sentence.punctuation),
    };
  }

  private processSegments(
    segments: string[][], nested: boolean,
    preview: boolean, punctuation: LexicalPunctuation | null): string[] {
    if ((segments.length === 1 && segments[0].length === 0) ||
      (segments.length === 1 && segments[0].length === 1 && segments[0][0] === '')) {
      return [];
    }
    const res = combine(segments);

    if (!nested) {
      if (typeof punctuation === 'string') {
        return res.map(ls => sententize(ls, true, preview === false, punctuation));

      } else {
        return res.map(ls => sententize(ls, preview ? false : true, preview === false));
      }
    } else {
      return res.map(r => r.join(' '));
    }
  }

  private processLemmaSegments(
    segment: (string | LemmaSequence)[][], nested: boolean,
    preview: boolean, punctuation: LexicalPunctuation | null): LemmaSequence[] {
    const res = combine(segment).map(ls => Array.prototype.concat.apply([], ls));

    if (!nested) {
      if (typeof punctuation === 'string') {
        return res.map(ls => lemmaSententize(ls, true, preview === false, punctuation));

      } else {
        return res.map(ls => lemmaSententize(ls, preview ? false : true, preview === false));
      }
    }
    return res;
  }

  private generateForms(
    wordDb: WordDb,
    generator: WordGenerator,
    phrases: Phrase[],
    correct: boolean,
    isFirst: boolean,
  ): Morphed {
    let stringsResult: string[] = [];
    let lemmasResult: Lemmatized[] = [];

    if (generator.tense) {
      const phrase = generator.phrase as string;
      const word = wordDb[phrase];
      if (!word || !word.length) {
        throw Error(`Verb ${phrase} not found in Phrases.`);
      }

      const verb = this.verbMorph(
        wordDb,
        phrase,
        generator.tense,
        generator.number,
        generator.gender,
        generator.person,
        generator.negation,
        generator.prefix,
        generator.suffix,
        generator.perfectiveAspect,
        generator.reflexivePronoun,
        correct,
        isFirst,
      );

      stringsResult = stringsResult.concat(verb.forms);
      if (correct) lemmasResult = lemmasResult.concat(this.createLemmatized(verb.forms, [verb.lemma]));

      return {strings: stringsResult, lemmatized: lemmasResult};
    }

    if (!Array.isArray(generator.acceptedForm)) {
      generator.acceptedForm = [generator.acceptedForm];
    }

    let type: LexicalType;
    let gender: LexicalGender;
    let forms: string[];
    let lemmaForms: string[];

    for (const outputForm of generator.acceptedForm) {
      let inputPhrase: AdjectiveNounAdverb = {
        adjective: null,
        noun: null,
        adverb: null,
      };

      if (typeof generator.phrase === 'string') {
        const word = wordDb[generator.phrase];

        if (!word || !word.length) {
          throw Error(`Noun or adjective ${generator.phrase} not found in Phrases.`);
        }
        type = word[0].type;

        // NOTE: condition below is only for typing
        if (type === 'verb' || type === 'helpverb' || type === 'reflexpronoun') {
          throw Error(`${generator.phrase} should be noun or adjective, but verb, helpverb or reflexpronoun was found`);
        }
        inputPhrase[type] = generator.phrase;
      } else {
        inputPhrase = {...generator.phrase};
      }

      if (inputPhrase.noun) {
        const word = wordDb[inputPhrase.noun];
        if (!word || !word.length) {
          throw Error(`${inputPhrase.noun} not found in Phrases.`);
        }
        gender = word[0].gender;
      } else {
        gender = generator.gender;
      }

      switch (outputForm) {
        case PartOfSpeech.Adverb:
          forms = this.morphAdverb(wordDb, inputPhrase.adverb, generator.grade, correct);
          lemmaForms = this.morphAdverb(wordDb, inputPhrase.adverb, Grade.Positive, correct);
          stringsResult.push(...forms);
          if (correct) {
            lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
          }
          break;

        case PartOfSpeech.Noun:
          inputPhrase.adjective = null;
          if (!inputPhrase.noun) continue;

          forms = this.morphNounAdjective(wordDb, inputPhrase, generator.case, generator.number, gender, correct);
          lemmaForms = this.morphNounAdjective(wordDb, inputPhrase, 1, generator.number, gender, correct);
          stringsResult.push(...forms);
          if (correct) {
            lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
          }
          break;

        case PartOfSpeech.Adjective:
          if (inputPhrase.adjective) {
            inputPhrase.noun = null;
            forms = this.morphNounAdjective(
              wordDb, inputPhrase, generator.case, generator.number, gender, correct, generator.grade,
            );
            lemmaForms = this.morphNounAdjective(
              wordDb, inputPhrase, 1, generator.number, gender, correct, Grade.Positive,
            );

            stringsResult.push(...forms);
            if (correct) {
              lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
            }
          } else {
            const noun = phrases.find(p => p.noun === this.getLemmaRoot(inputPhrase.noun));
            if (!noun) continue;

            const adjectives = noun.adjectives || [];
            for (const adjective of adjectives) {
              forms = this.morphNounAdjective(
                wordDb, {adjective}, generator.case, generator.number, gender, correct,
              );
              lemmaForms = this.morphNounAdjective(wordDb, {adjective}, 1, generator.number, gender, correct);
              stringsResult.push(...forms);
              if (correct) {
                lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
              }
            }
          }
          break;

        case 'adjective noun':
        default:
          if (!inputPhrase.noun) continue;

          if (inputPhrase.adjective) {
            forms = this.morphNounAdjective(wordDb, inputPhrase, generator.case, generator.number, gender, correct);
            lemmaForms = this.morphNounAdjective(wordDb, inputPhrase, 1, generator.number, gender, correct);

            stringsResult.push(...forms);
            if (correct) {
              lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
            }
          } else {
            const noun = phrases.find(p => p.noun === this.getLemmaRoot(inputPhrase.noun));
            if (!noun) continue;

            const adjectives = noun.adjectives || [];
            for (const adjective of adjectives) {
              forms = this.morphNounAdjective(wordDb, {...inputPhrase, adjective}, generator.case, generator.number,
                gender, correct);
              lemmaForms = this.morphNounAdjective(wordDb, {...inputPhrase, adjective}, 1, generator.number,
                gender, correct);
              stringsResult.push(...forms);
              if (correct) {
                lemmasResult.push(...this.createLemmatized(forms, lemmaForms));
              }
            }
          }
      }
    }
    return {
      strings: stringsResult,
      lemmatized: lemmasResult,
    };
  }

  private createLemmatized(forms: string[], lemmaForms: string[]): Lemmatized[] {
    return combine([distinct(forms), lemmaForms.slice(0, 1)]).map(l => ({correct: l[0], lemma: l[1]}));
  }

  private czechTenseForLemma(tense: LexicalTense): string {
    switch (tense) {
      case Tense.Present:
        return 'přítomný';
      case Tense.Future:
        return 'budoucí';
      case Tense.Past:
        return 'minulý';
      case Tense.PastOrPresent:
        return 'minulý nebo přítomný';
      case 'any':
        return 'neurčitý';
    }
  }

  private czechPersonForLemma(person: LexicalPerson, number: LexicalNumber, gender: LexicalGender): string {
    switch (person) {
      case Person.First:
        if (number === NumberEnum.Singular) return 'já';
        else if (number === NumberEnum.Plural) return 'my';
      case Person.Second:
        if (number === NumberEnum.Singular) return 'ty';
        else if (number === NumberEnum.Plural) return 'vy';
      case Person.Third:
        if (number === NumberEnum.Singular && gender) {
          switch (gender) {
            case Gender.Feminine:
              return 'ona';
            case Gender.Neuter:
              return 'ono';
            case Gender.MasculineAnimate:
              return 'on';
            case Gender.MasculineInanimate:
              return '';
          }
        } else if (number === NumberEnum.Plural && gender) {
          switch (gender) {
            case Gender.Feminine:
              return 'ony';
            case Gender.Neuter:
              return 'ona';
            case Gender.MasculineAnimate:
              return 'oni';
            case Gender.MasculineInanimate:
              return '';
          }
        }
      default:
        return '';
    }
  }

  private verbMorph(
    wordDb: WordDb, phrase: string, lexTense: LexicalTense, lexNumber: LexicalNumber, gender: BasicLexicalGender,
    lexPerson: LexicalPerson, negation: boolean, prefix: string, suffix: string,
    perfectiveAspect: LexicalPerfectiveAspect, reflexivePronoun: LexicalReflexivePronoun, correct: boolean,
    isFirst: boolean,
  ): VerbForms {
    if (!phrase) return {forms: [], lemma: ''};

    const forms = wordDb[phrase];
    if (!forms) {
      return {forms: [], lemma: ''};
    }

    const addHelpingVerb = (
      (lexTense === Tense.Future && perfectiveAspect === PerfectiveAspect.Imperfect) ||
      (lexTense === Tense.Past && (lexPerson === Person.First || (lexPerson === Person.Second && !reflexivePronoun)))
    );

    let chosenForms = forms.filter(form => (
      form.type === 'verb' &&
      ((negation === undefined && form.negation === false) || form.negation === negation) &&
      form.colloquial === false &&
      (form.variant === undefined || form.variant === 1) &&
      (form.grade === undefined || form.grade === 1)),
    );
    let chosenStrings: string[] = [];

    if (correct) {
      chosenForms = this.filterTense(lexTense, chosenForms, correct);
      chosenForms = this.filterPerson(lexPerson, chosenForms, correct);
      chosenForms = this.filterNumber(lexNumber, chosenForms, correct);
      chosenForms = this.filterGender(gender, chosenForms, correct);
      chosenForms = this.filterColloquial(chosenForms);
      chosenForms = this.filterDubleta(chosenForms);

      if (chosenForms) {
        if (addHelpingVerb) {
          if (lexTense === Tense.Future && perfectiveAspect === PerfectiveAspect.Imperfect) {
            // there is not chosenForm for this variant, add infinitive if exists
            let inf = this.getInfinitive(cloneDeep(forms), negation);
            if (inf) {
              chosenForms = [inf];
            }
          }
          chosenStrings = this.addHelpingVerb(
            wordDb, chosenForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst);
        } else {
          // HERE the chosenForms could be undefined!!!
          chosenStrings = distinct(chosenForms.map(f => f.form));
        }

        if (reflexivePronoun) {
          chosenStrings = this.addReflexivePronoun(
            wordDb, chosenStrings, lexTense, lexNumber, lexPerson, reflexivePronoun, addHelpingVerb, correct, isFirst);
        }
      }
    } else {
      const incorrectTypes = ['person', 'gender', 'number'];
      let incorrectForms;
      let incorrectStrings;
      if (lexTense === Tense.Present || (lexTense === Tense.Future &&
        (perfectiveAspect === PerfectiveAspect.Perfect || !perfectiveAspect))) {
        incorrectForms = this.filterTense(lexTense, chosenForms, correct, incorrectTypes);
        incorrectForms = this.filterPerson(lexPerson, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterNumber(lexNumber, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterGender(gender, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterColloquial(incorrectForms);
        incorrectForms = this.filterDubleta(incorrectForms);
        if (incorrectForms) {
          if (addHelpingVerb) {
            incorrectStrings = this.addHelpingVerb(
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, true, isFirst);
          } else {
            incorrectStrings = distinct(incorrectForms.map(f => f.form));
          }

          if (reflexivePronoun) {
            incorrectStrings = this.addReflexivePronoun(
              wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
              addHelpingVerb, true, isFirst)
              .concat(this.addReflexivePronoun(
                wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
                addHelpingVerb, correct, isFirst, ['wordOrder']));
          }
        }

      } else if (lexTense === Tense.Future && perfectiveAspect === PerfectiveAspect.Imperfect) {
        if (addHelpingVerb) {
          // there is not chosenForm for this variant, add infinitive if exists
          let inf = this.getInfinitive(cloneDeep(forms), negation);
          if (inf) {
            incorrectForms = [inf];
            incorrectStrings = this.addHelpingVerb(
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbPerson1Future']);
          }

        } else {
          incorrectForms = this.filterTense(lexTense, chosenForms, correct, incorrectTypes);
          incorrectForms = this.filterPerson(lexPerson, incorrectForms, correct, incorrectTypes);
          incorrectForms = this.filterNumber(lexNumber, incorrectForms, correct, incorrectTypes);
          incorrectForms = this.filterGender(gender, incorrectForms, correct, incorrectTypes);
          incorrectForms = this.filterColloquial(incorrectForms);
          incorrectForms = this.filterDubleta(incorrectForms);
          incorrectStrings = distinct(incorrectForms.map(f => f.form));
        }

        if (reflexivePronoun) {
          incorrectStrings = this.addReflexivePronoun(
            wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, true, isFirst)
            .concat(this.addReflexivePronoun(
              wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
              addHelpingVerb, correct, isFirst, ['wordOrder']));
        }

      } else if (lexTense === Tense.Past) {
        incorrectForms = this.filterTense(lexTense, chosenForms, correct, incorrectTypes);
        incorrectForms = this.filterPerson(lexPerson, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterNumber(lexNumber, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterGender(gender, incorrectForms, correct, incorrectTypes);
        incorrectForms = this.filterColloquial(incorrectForms);
        incorrectForms = this.filterDubleta(incorrectForms);

        if (addHelpingVerb && reflexivePronoun) {
          let varIncorrectString;
          varIncorrectString = this.addHelpingVerb( // jsem/jsme
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbNumberPluralForPlural']);
          incorrectStrings = this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber,
            lexPerson, reflexivePronoun, addHelpingVerb, true, isFirst);

          varIncorrectString = this.addHelpingVerb( // jste
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbPerson2', 'helpingVerbNumberPluralForAll']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, true, isFirst));

          varIncorrectString = this.addHelpingVerb( // ses
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbMissingPluralForSingular']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, correct, isFirst, ['reflexivePronoun']));

          varIncorrectString = this.addHelpingVerb( // se
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbMissingSingularForAll']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, true, isFirst));

          varIncorrectString = this.addHelpingVerb( // jsem/jsme ordered
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbNumberPluralForPlural', 'wordOrder']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString,
            lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, correct, isFirst, ['wordOrder']));

          varIncorrectString = this.addHelpingVerb( // jste ordered
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbPerson2', 'helpingVerbNumberPluralForAll', 'wordOrder']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, correct, isFirst, ['wordOrder']));

          varIncorrectString = this.addHelpingVerb( // ses ordered
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbMissingPluralForSingular', 'wordOrder']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, correct, isFirst, ['reflexivePronoun']));

          varIncorrectString = this.addHelpingVerb( // se ordered
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbMissingSingularForAll', 'wordOrder']);
          incorrectStrings = incorrectStrings.concat(this.addReflexivePronoun(
            wordDb, varIncorrectString, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, true, isFirst));

        } else if (addHelpingVerb) {
          incorrectStrings = this.addHelpingVerb(
            wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
            ['helpingVerbMissingSingularForAll'])
            .concat(this.addHelpingVerb(
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['wordOrder']))
            .concat(this.addHelpingVerb( // jsi/jste
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbPerson2', 'helpingVerbNumberPluralForPlural']))
            .concat(this.addHelpingVerb( // jsem/jsme
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbNumberPluralForPlural']))
            .concat(this.addHelpingVerb( // jste
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbPerson2', 'helpingVerbNumberPluralForAll']))
            .concat(this.addHelpingVerb( // jsi/jste ordered singular
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbPerson2', 'helpingVerbNumberSingularForAll', 'wordOrder']))
            .concat(this.addHelpingVerb( // jsi/jste ordered plural
              wordDb, incorrectForms, lexTense, lexNumber, lexPerson, negation, correct, isFirst,
              ['helpingVerbPerson2', 'helpingVerbNumberPluralForAll', 'wordOrder']));

        } else if (reflexivePronoun) {
          incorrectStrings = distinct(incorrectForms.map(f => f.form));
          incorrectStrings = this.addReflexivePronoun(
            wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
            addHelpingVerb, true, isFirst)
            .concat(this.addReflexivePronoun(
              wordDb, incorrectStrings, lexTense, lexNumber, lexPerson, reflexivePronoun,
              addHelpingVerb, correct, isFirst, ['wordOrder']));

        } else {
          incorrectStrings = distinct(incorrectForms.map(f => f.form));
        }
      }

      chosenStrings = chosenStrings.concat(incorrectStrings);
    }

    chosenStrings = distinct(chosenStrings).map(s => this.wordAffix(s, prefix, suffix));

    let lemma = '';
    let infinitive = this.getInfinitive(forms, negation);
    if (chosenForms?.length && infinitive) {
      let lemmaVerb = this.wordAffix(infinitive.form, prefix, suffix);
      if (reflexivePronoun) lemmaVerb = lemmaVerb + ' ' + reflexivePronoun;
      lemma = this.trimLemma(lemmaVerb) + ' | ';
      const lemmaPerson = this.czechPersonForLemma(lexPerson, lexNumber, gender);
      if (lemmaPerson.length > 0) lemma = lemma + lemmaPerson + ' | ';
      lemma = lemma + this.czechTenseForLemma(lexTense);
    }

    return {forms: chosenStrings, lemma};
  }

  private getInfinitive(
    wordForms: WordForm[],
    negation: boolean,
  ): WordForm | undefined {
    return wordForms.find(f => {
      return (
        f.tag[1] === 'f' && // subPos => infinitive
        (f.variant === 1 || f.variant === undefined) &&
        (negation === f.negation || f.negation === undefined)
      );
    });
  }

  private filterDubleta(chosenForms: WordForm[]) {
    if (chosenForms && chosenForms.length > 1 &&
      (chosenForms[0].lemma.endsWith('ovat') || chosenForms[0].lemma.match(/[áíéóý]+t$/))) {
      return chosenForms.filter(chF => {
        return (
          // NOTE: X hrát/hráli
          !(chF.form.endsWith('i') && chF.person !== 3 && chF.tense !== 'past' && chF.number !== 'plural') &&
          !chF.form.endsWith('í')
        );
      });
    }

    if (chosenForms && chosenForms.length > 1 && chosenForms[0].lemma.endsWith('ět')) {
      return chosenForms.filter(chF => !chF.form.endsWith('jí'));
    }

    return chosenForms;
  }

  private filterColloquial(chosenForms: WordForm[]) {
    if (chosenForms && chosenForms.length > 1) {
      return chosenForms.filter(chF => !(chF.form.endsWith('s') && chF.person === 2));
    }
    return chosenForms;
  }

  private filterTense(
    lexTense: LexicalTense, chosenForms: WordForm[], correct: boolean, incorrectTypes?: string[]): WordForm[] {
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];
    return chosenForms.filter(form => {
      if (!correct && incorrectTypes.includes('tense')) {
        return true;
      } else {
        return form.tense === lexTense || form.tense === Tense.Any ||
          (form.tense === Tense.PresentOrFuture && (lexTense === Tense.Present || lexTense === Tense.Future));
      }
    });
  }

  private filterNumber(
    lexNumber: LexicalNumber, chosenForms: WordForm[], correct: boolean, incorrectTypes?: string[]): WordForm[] {
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];
    return chosenForms.filter(form => {
      if (!correct && incorrectTypes.includes('number')) {
        return true;
      } else {
        return form.number === lexNumber || form.number === undefined;
      }
    });
  }

  private filterPerson(
    person: LexicalPerson, chosenForms: WordForm[], correct: boolean, incorrectTypes?: string[]): WordForm[] {
    if (!person) return chosenForms;
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];

    let res: WordForm[] = [];
    distinct([person, undefined]).forEach(p => {
      res = res.concat(chosenForms.filter(form => {
        if (!correct && incorrectTypes.includes('person')) {
          return true;
        } else {
          return form.person === p;
        }
      }));
    });
    return res;
  }

  private filterGender(
    gender: BasicLexicalGender, chosenForms: WordForm[], correct: boolean, incorrectTypes?: string[]): WordForm[] {
    if (!gender) return chosenForms;
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];

    let genderSet;
    switch (gender) {
      case Gender.Feminine: {
        genderSet = ['feminine', 'feminine or neuter', 'masculine inanimate or feminine (plural only)',
          'feminine (with singular only) or neuter (with plural only)', 'any of the basic four genders'];
        break;
      }
      case Gender.Neuter: {
        genderSet = ['neuter', 'feminine or neuter',
          'feminine (with singular only) or neuter (with plural only)', 'any of the basic four genders'];
        break;
      }
      case Gender.MasculineAnimate: {
        genderSet = ['masculine animate', 'masculine (either animate or inanimate)',
          'any of the basic four genders'];
        break;
      }
      case Gender.MasculineInanimate:
        genderSet = ['masculine inanimate', 'masculine (either animate or inanimate)',
          'masculine inanimate or feminine (plural only)', 'any of the basic four genders'];
    }

    for (const g of genderSet) {
      const res = chosenForms.filter(form => {
        if (!correct && incorrectTypes.includes('gender')) {
          return true;
        } else {
          return form.gender === g || (form.gender === undefined && g === 'any of the basic four genders');
        }
      });
      if (res.length > 0) {
        // Last in filtering sequence, so we can return only first result,
        // because there is obviously no other proper one
        return res;
      }
    }
  }

  private addHelpingVerb(
    wordDb: WordDb, chosenForms: WordForm[], lexTense: LexicalTense, lexNumber: LexicalNumber,
    lexPerson: LexicalPerson, negation: boolean, correct: boolean, isFirst: boolean, incorrectTypes?: string[],
  ): string[] {
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];
    const forms = wordDb[this.helpingVerb.noun];
    const helpingVerbsAll = forms.filter(form => (
      form.type === 'helpverb' &&
      ((negation === undefined && form.negation === false) || form.negation === negation)
    ));

    const res: string[] = [];
    chosenForms.forEach(f => {
      let helpingVerbs = helpingVerbsAll;
      if (correct || (!correct && !incorrectTypes.includes('helpingVerbTense'))) {
        helpingVerbs = this.filterTense(lexTense, helpingVerbs, true);

      } else {
        helpingVerbs = this.filterTense(lexTense, helpingVerbs, false, ['tense']);
      }

      if (!correct) {
        if (incorrectTypes.includes('wordOrder') && !incorrectTypes.includes('helpingVerbPerson2')) {
          helpingVerbs = this.filterPerson(lexPerson, helpingVerbs, true);

        } else if (incorrectTypes.includes('helpingVerbPerson2')) {
          helpingVerbs = this.filterPerson(2, helpingVerbs, true);

        } else if (incorrectTypes.includes('helpingVerbPerson1Future')) {
          helpingVerbs = this.filterPerson(lexPerson, helpingVerbs, false, ['person']);
        }

      } else if (correct) {
        helpingVerbs = this.filterPerson(lexPerson, helpingVerbs, true);
      }

      if (!correct) {
        if (f.number === NumberEnum.Plural && incorrectTypes.includes('wordOrder')) {
          helpingVerbs = this.filterNumber(f.number, helpingVerbs, true);

        } else if (f.number === NumberEnum.Plural && incorrectTypes.includes('helpingVerbNumberPluralForPlural')) {
          helpingVerbs = this.filterNumber(f.number, helpingVerbs, true);

        } else if (incorrectTypes.includes('helpingVerbNumberPluralForAll')) {
          helpingVerbs = this.filterNumber(NumberEnum.Plural, helpingVerbs, true);

        } else if (incorrectTypes.includes('helpingVerbNumberSingularForAll')) {
          helpingVerbs = this.filterNumber(NumberEnum.Singular, helpingVerbs, true);

        } else if (incorrectTypes.includes('helpingVerbPerson1Future')) {
          helpingVerbs = this.filterNumber(lexNumber, helpingVerbs, false, ['number']);
          // NOTE: index 0 is pushed below
          helpingVerbs.slice(1).forEach(hv => {
            if (lexTense === 'past' && isFirst) {
              res.push(f.form + ' ' + hv.form);
            } else {
              res.push(hv.form + ' ' + f.form);
            }
          });
        }

      } else if (correct) {
        helpingVerbs = this.filterNumber(lexNumber, helpingVerbs, true);
      }

      if (!correct &&
        (incorrectTypes.includes('helpingVerbMissingSingularForAll') ||
          incorrectTypes.includes('helpingVerbMissingPluralForSingular'))) {
        // NOTE: We want to also skip this and do not continue to other conditions
        if (incorrectTypes.includes('helpingVerbMissingSingularForAll') ||
          (incorrectTypes.includes('helpingVerbMissingPluralForSingular') &&
            (f.number === NumberEnum.Singular || f.number === undefined))) {
          // Hral fotbal.
          res.push(f.form);
        }

      } else if (!correct && incorrectTypes.includes('wordOrder')) {
        // Jsem hral fotbal. Vcera hral jsem fotbal.
        if (lexTense === Tense.Past && isFirst) {
          res.push(helpingVerbs[0].form + ' ' + f.form);

        } else {
          res.push(f.form + ' ' + helpingVerbs[0].form);
        }

      } else if (lexTense === Tense.Past && isFirst) {
        // Co jsi delal vcera? Hral jsem fotbal.
        // Hral jsem fotbal.
        // Hral jsem fotbal vcera?
        res.push(f.form + ' ' + helpingVerbs[0].form);

      } else {
        // Co jsi delal vcera? Vcera jsem hral fotbal.
        // Vcera jsem hral fotbal.
        // Co jsem hral vcera?
        // Co budes delat zitra? Budu hrat fotbal.
        // Co budes delat zitra? Zitra budu hrat fotbal.
        // Zitra budu hrat fotbal.
        // Budu hrat zitra fotbal?
        res.push(helpingVerbs[0].form + ' ' + f.form);
      }
    });

    return distinct(res);
  }

  private addReflexivePronoun(
    wordDb: WordDb, chosenStrings: string[], lexTense: LexicalTense,
    lexNumber: LexicalNumber, lexPerson: LexicalPerson, reflexivePronoun: LexicalReflexivePronoun,
    addHelpingVerb: boolean, correct: boolean, isFirst: boolean, incorrectTypes?: string[],
  ): string[] {
    if (!reflexivePronoun) return chosenStrings;
    if (!Array.isArray(incorrectTypes)) incorrectTypes = [];

    const wordforms = wordDb[this.reflexivePronoun.noun] as WordForm[];
    wordforms.forEach((f: WordForm) => {
      if (f.form === reflexivePronoun) {
        if ((correct && lexTense === Tense.Past && lexNumber === NumberEnum.Singular && lexPerson === Person.Second) ||
          (!correct && incorrectTypes.includes('reflexivePronoun') &&
            (lexTense !== Tense.Past || lexNumber !== NumberEnum.Singular || lexPerson !== Person.Second))) {
          reflexivePronoun = f.pastSingularPerson2Form as LexicalReflexivePronoun;
        } else {
          reflexivePronoun = f.form as LexicalReflexivePronoun;
        }
      }
    });

    const res: string[] = [];
    chosenStrings.forEach(verbPhrase => {
      const words = verbPhrase.split(' ');

      if (!correct && incorrectTypes.includes('wordOrder')) {
        if (!addHelpingVerb && (lexTense === Tense.Past || lexTense === Tense.Present) && isFirst) {
          verbPhrase = reflexivePronoun + ' ' + verbPhrase;

        } else if (addHelpingVerb && words.length > 1 &&
          (lexTense === Tense.Past || lexTense === Tense.Future) && isFirst) {
          verbPhrase = words[0] + ' ' + reflexivePronoun + ' ' + words[1];

        } else {
          verbPhrase = verbPhrase + ' ' + reflexivePronoun;
        }

      } else if ((lexTense === Tense.Past || lexTense === Tense.Present) && isFirst) {
        // Koukal jsem se na film
        // Koukali se na film.
        // Vzal sis dres?
        // Hraju si rád.
        verbPhrase = verbPhrase + ' ' + reflexivePronoun;

      } else if (addHelpingVerb && words.length > 1 &&
        (lexTense === Tense.Past || (lexTense === Tense.Future && isFirst))) {
        // Ráno jsem se koukal na film.
        // Co bude dělat Christian? Bude se koukat na film.
        verbPhrase = words[0] + ' ' + reflexivePronoun + ' ' + words[1];

      } else {
        // Christian se bude koukat na film.
        // Ráno se koukali na film.
        // Ráno sis vzal dres.
        // Rád si hraju.
        // Christian si vezme dres.
        verbPhrase = reflexivePronoun + ' ' + verbPhrase;
      }

      res.push(verbPhrase);
    });

    return distinct(res);
  }

  private trimLemma(lemma: string): string {
    lemma = lemma.replace('_:T', '');
    return lemma.replace(/-.*\)/, '');
  }
}
