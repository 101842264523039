<table>
  <thead>
    <th style="width: 15%;">Noun</th>
    <th style="width: 20%;">Image</th>
    <th style="width: 25%;">Sound</th>
    <th colspan="2" style="width: 40%;">Definition</th>
  </thead>
  <ng-container *ngFor="let phrase of phrases | async">
    <tr class="phrase-row">
      <td>{{phrase.noun}}<br></td>
      <td><img *ngIf="phrase.img" [src]="phrase.img" style="max-width: 200px;"></td>
      <td><audio *ngIf="phrase.snd" [src]="phrase.snd" controls></audio></td>
      <td>{{phrase.def}}</td>
    </tr>
  </ng-container>
</table>
