import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

import {FirebaseService} from './firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  userProfile$ = this.firebaseService.userProfile;
  form: any;

  constructor(
    private formbuilder: FormBuilder,
    public firebaseService: FirebaseService,
    private _snack: MatSnackBar,
  ) {
    this.form = this.formbuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.firebaseService.initAuthStateChanged();
  }

  login() {
    this.firebaseService.login(this.form.value.email, this.form.value.password)
      .then(_ => {
        this._snack.open('Welcome', 'OK', {duration: 3_000});
        this.form.reset();
      })
      .catch(e => alert(e.message));
  }

  logout() {
    this.firebaseService.logout()
    .then(_ => this._snack.open('Logout successful', 'OK', {duration: 3_000}))
    .catch(e => alert(e.message));
  }
}
