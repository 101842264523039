import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {child, DatabaseReference, off, onValue, remove, update} from '@angular/fire/database';
import * as _ from 'lodash';
import {BehaviorSubject, Subject} from 'rxjs';

import {FirebaseService} from '../../firebase.service';
import {FirebaseStatement} from '../../generator.types';

@Component({
  selector: 'app-preview-sentences',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnDestroy, OnInit {
  topicId: string;
  phrases = new Subject<any[]>();
  sentences = new BehaviorSubject<FirebaseStatement[]>([]);
  questions = new BehaviorSubject<FirebaseStatement[]>([]);
  showSentences = true;
  showQuestions = true;

  @Input() published: boolean;
  @Output() editStatement = new EventEmitter();
  statementToEdit = new BehaviorSubject<any>(null);

  phrasesDbReference: DatabaseReference;
  sentencesDbReference: DatabaseReference;
  questionsDbReference: DatabaseReference;
  publishedDbReference: DatabaseReference;
  incorrectSentences: Record<string, boolean[]>;
  incorrectQuestions: Record<string, boolean[]>;

  @Input() set topic(topic: string) {
    this.topicId = topic;

    this.phrases.next([]);
    this.sentences.next([]);
    this.questions.next([]);

    if (this.phrasesDbReference) off(this.phrasesDbReference);
    if (this.sentencesDbReference) off(this.sentencesDbReference);
    if (this.questionsDbReference) off(this.questionsDbReference);

    this.phrasesDbReference = child(this.firebaseService.database('topicPhrases'), topic);
    this.sentencesDbReference = child(this.firebaseService.database('topicSentences'), topic);
    this.questionsDbReference = child(this.firebaseService.database('topicQuestions'), topic);

    onValue(this.phrasesDbReference, snapshot => {
      const phrases = snapshot.val();
      const phrasesArray = phrases ? Object.keys(phrases).map(id => ({id, ...phrases[id]})) : [];
      this.phrases.next(phrasesArray);
    });

    onValue(this.sentencesDbReference, snapshot => {
      const sentences = snapshot.val();
      const sentencesArray = sentences ? Object.keys(sentences)
        .map(id => (({id, ...sentences[id]}) as FirebaseStatement)) : [];

      this.incorrectSentences = this.getModel(sentencesArray.reverse());
      this.sentences.next(sentencesArray);
    });

    onValue(this.questionsDbReference, snapshot => {
      const questions = snapshot.val();

      const questionsArray = questions ? Object.keys(questions)
        .map(id => (({id, ...questions[id]}) as FirebaseStatement)) : [];

      this.incorrectQuestions = this.getModel(questionsArray.reverse());
      this.questions.next(questionsArray);
    });
  }

  constructor(
    private firebaseService: FirebaseService,
  ) { }

  ngOnDestroy() {
    off(this.phrasesDbReference);
    off(this.sentencesDbReference);
    off(this.questionsDbReference);
  }

  async remove(sentence: FirebaseStatement) {
    if (sentence.answer) {
      await remove(child(this.questionsDbReference, sentence.id));
    } else {
      await remove(child(this.sentencesDbReference, sentence.id));
    }
  }

  edit(statement: FirebaseStatement) {
    this.statementToEdit.next(_.cloneDeep(statement));
    this.editStatement.emit(statement.id);
  }

  ngOnInit() {
    if (this.publishedDbReference) {
      off(this.publishedDbReference);
    } else {
      this.publishedDbReference = this.firebaseService.database('generatedTopics/sentences/' + this.topicId);
    }

    if (this.published) {
      onValue(this.publishedDbReference, snapshot => {
        const sentences = snapshot.val();
        const sentencesArray: FirebaseStatement[] = sentences ? Object.keys(sentences)
          .map(id => ({id, generated: sentences[id]})) : [];
        this.sentences.next(sentencesArray.filter(s => s.generated[0].question === '').reverse());
        this.questions.next(sentencesArray.filter(s => s.generated[0].question !== '').reverse());
      });
    }
  }

  trackByIndex(index: number) {
    return index;
  }

  async changeConfirmedSentences(
    sentenceId: string,
    indexOfIncorrect: number,
    confirmed: boolean,
    answer: string | Record<string, string | boolean>,
  ) {
    await update(
      child(
        child(this.sentencesDbReference, sentenceId),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        'generated/0/incorrect/' + indexOfIncorrect,
      ),
      {
        sentence: answer,
        confirmed: Boolean(confirmed),
      });
  }

  async changeConfirmedQuestions(
    sentenceId: string,
    indexOfIncorrect: number,
    confirmed: boolean,
    answer: string | Record<string, string | boolean>,
  ) {
    await update(
      child(
        child(this.questionsDbReference, sentenceId),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        'generated/0/incorrect/' + indexOfIncorrect),
      {
        sentence: answer,
        confirmed: Boolean(confirmed),
      });
  }

  private getModel(data: FirebaseStatement[]) {
    return data.reduce((acc, sentence) => {
      if (sentence.generated[0] && sentence.generated[0].incorrect) {
        acc[sentence.id] = sentence.generated[0].incorrect.map(i => !_.isNil(i.confirmed) ? i.confirmed : true);
      }
      return acc;
    }, {} as Record<string, boolean[]>);
  }

}
