import {Injectable} from '@angular/core';
import {Auth, onAuthStateChanged, signInWithEmailAndPassword, signOut, User} from '@angular/fire/auth';
import {getDatabase, ref as database_ref} from '@angular/fire/database';
import {doc, getFirestore} from '@angular/fire/firestore';
import {getStorage, ref as storage_ref} from '@angular/fire/storage';
import {Subject} from 'rxjs';

@Injectable()
export class FirebaseService {
  userProfile = new Subject<User>();
  basePath = 'admin';

  constructor(public auth: Auth) {
  }

  initAuthStateChanged() {
    onAuthStateChanged(this.auth, this.userProfile);
  }

  async login(email: string, password: string) {
    return await signInWithEmailAndPassword(this.auth, email, password);
  }

  async logout() {
    return await signOut(this.auth);
  }

  database(path = '') {
    const database = getDatabase();
    return database_ref(database, this._wholePath(path));
  }

  firestore(path = '') {
    const firestore = getFirestore();
    return doc(firestore, this._wholePath(path));
  }

  storage(path = '') {
    const storage = getStorage();
    return storage_ref(storage, this._wholePath(path));
  }

  private _wholePath(path = '') {
    if (path !== '') {
      return this.basePath + '/' + path;
    } else {
      return this.basePath;
    }
  }
}
