import {Injectable} from '@angular/core';
import {collection, getDocs} from '@angular/fire/firestore';
import {deleteObject} from '@angular/fire/storage';
import {FirebaseService} from './firebase.service';

@Injectable()
export class StorageService {

  constructor(private firebaseService: FirebaseService) { }

  async removePhraseObjects(id: string) {
    await Promise.all([
      deleteObject(this.firebaseService.storage('phrases/' + id + '/img')),
      deleteObject(this.firebaseService.storage('phrases/' + id + '/snd')),
    ]);
  }

  async removePhrasesObjectsByTopic(topicId: string) {
    const [ phrases, phrasesAdjectives ] = await Promise.all([
      getDocs(collection(this.firebaseService.firestore('topicPhrases/'), topicId)),
      getDocs(collection(this.firebaseService.firestore('topicPhraseAdjectives/'), topicId)),
    ]);

    const phrasesIds: string[] = phrases.docs.map(doc => doc.id);
    const phrasesAdjectivesIds: string[] = phrasesAdjectives.docs.flatMap(doc => Object.keys(doc.data()[doc.id]));

    await Promise.all([
      ...phrasesIds.map(id => this.removePhraseObjects(id)),
      ...phrasesAdjectivesIds.map(id => this.removePhraseObjects(id)),
    ]);
 }
}
