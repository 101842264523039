import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PartOfSpeech} from '../../generator.constants';
import {ComponentPhrase, WordForm} from '../../generator.types';

@Component({
  selector: 'app-topic-phrase-forms',
  templateUrl: './topic-phrase-forms.component.html',
  styleUrls: ['./topic-phrase-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicPhraseFormsComponent {
  @Input() phrase: ComponentPhrase;
  @Output() removeLemmaProper = new EventEmitter();
  @Output() removeAddInfo = new EventEmitter();
  @Output() removeWordForm = new EventEmitter();

  wordTypes = PartOfSpeech;

  countSubItemsLength(outerItems: { items: object[] }[]) {
    return outerItems.reduce((acc, {items}) => acc + items.length, 0);
  }

  get getWordType(): string {
    return this.phrase && this.phrase.wordForms ? this.phrase.wordForms[0]?.items[0]?.items[0]?.type : '';
  }

  tagHumanized(form: WordForm): string {
    return `
    [0] Part of speech: ${form.type ?? '-'}
    [1] Detailed part of speech: not processed
    [2] Gender: ${form.gender ?? '-'}
    [3] Number: ${form.number ?? '-'}
    [4] Case: ${form.case ?? '-'}
    [5] Possessor's gender: not processed
    [6] Possessor's number: not processed
    [7] Person: ${form.person ?? '-'}
    [8] Tense: ${form.tense ?? '-'}
    [9] Grade: ${form.grade ?? '-'}
    [10] Negation: ${form.negation ?? '-'}
    [11] Voice: not processed
    [12] Reserve1: not processed
    [13] Reserve2: not processed
    [14] Variant: ${form.variant ?? '-'}
    `;
  }

  openMorphoditaTagStructure() {
    window.open('https://ufal.mff.cuni.cz/pdt2.0/doc/manuals/en/m-layer/html/ch02s02s01.html', '_blank');
  }

}
