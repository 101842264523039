<div class="header">
  <h3>Topics</h3>

  <a
    (click)="dragAllowed = !dragAllowed"
    mat-mini-fab
    [matTooltip]="dragAllowed ? 'Cancel dragging' : 'Change order of topics'"
    [color]="dragAllowed ? 'error' : 'primary'">
    <mat-icon>
      {{ dragAllowed ? 'close' : 'swap_vert' }}
    </mat-icon>
  </a>
</div>

<p *ngIf="dragAllowed" class="draggingInfo">
  <span>Start dragging items below using</span>
  <mat-icon>drag_handle</mat-icon>
</p>

<mat-list
  cdkDropList
  class="list"
  (cdkDropListDropped)="dropTopic($event)"
  role="list">
  <div
    class="list-item"
    cdkDrag
    cdkDragLockAxis="y"
    [cdkDragDisabled]="!dragAllowed"
    [cdkDragData]="topic"
    *ngFor="let topic of topics$ | async; let i = index">
    <mat-divider *ngIf="i === 0"></mat-divider>

    <mat-list-item>
      <a
        routerLinkActive="is-active"
        [routerLink]="['topic', topic.id]">
        <mat-icon *ngIf="dragAllowed">drag_handle</mat-icon>
        {{ topic.title }}
      </a>
    </mat-list-item>

    <mat-divider></mat-divider>
  </div>
</mat-list>

<div class="buttons">
  <a
    mat-flat-button
    color="accent"
    [routerLink]="['topic', 'new']">
    <mat-icon>add</mat-icon> Add new topic
  </a>
</div>
