import {isNil} from 'lodash';

import {QAs, QAsPublished} from '../../generator.types';

export const getConfirmedIncorrect = (generated: QAs[]): QAsPublished[] => {
  return generated.map((g: QAs) => {
    return {
      ...g,
      incorrect: g.incorrect ? g.incorrect
        .filter(incorrect => incorrect.confirmed === true || isNil(incorrect.confirmed))
        .map((a: any) => a.sentence ? a.sentence : a) : [],
    };
  });
};
