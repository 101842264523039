export const environment = {
  production: true,
  firebaseApi: {
    apiKey: 'AIzaSyDoXHmtuPtTMKapZGTfw8mneWHARVpwhGc',
    authDomain: 'cesky-levou-zadni-test.firebaseapp.com',
    databaseURL: 'https://cesky-levou-zadni-test-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'cesky-levou-zadni-test',
    storageBucket: 'cesky-levou-zadni-test.appspot.com',
    messagingSenderId: '339604193932',
  },
};
