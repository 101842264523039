import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './pages/home/home.component';
import {TopicNewPageComponent} from './pages/topic/topic-new.component';
import {TopicPageComponent} from './pages/topic/topic.component';
import {TopicsPageComponent} from './pages/topic/topics-wrapper.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'topic',
    children: [
      {
        path: 'new',
        component: TopicNewPageComponent,
      },
      {
        path: ':id',
        component: TopicPageComponent,
      },
    ],
    component: TopicsPageComponent,
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
