import {Component} from '@angular/core';

@Component({
  selector: 'app-topic-page-new',
  template: `
    <div class="content">
      <app-topic-settings type="add"></app-topic-settings>
    </div>
  `,
  styles: [],
})
export class TopicNewPageComponent {

  constructor() {}

}
