import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {child, DatabaseReference, off, onValue, push, remove, set, update} from '@angular/fire/database';
import {deleteObject, getDownloadURL, ref as storage_ref, StorageReference, uploadBytes} from '@angular/fire/storage';
import {FormBuilder, FormGroupDirective, Validators} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';

import {FirebaseService} from '../../firebase.service';
import {GeneratorService} from '../../generator.service';
import {GroupedWordForms, Phrase, PhraseMedia} from '../../generator.types';
import {StorageService} from '../../storage.service';

interface RemoveFormParams {
  phraseKey: string;
  lemmaProperIndex?: number;
  addInfoIndex?: number;
  wordFormIndex?: number;
}

const UPLOAD_FILE_IMG = 'Upload image file';
const UPLOAD_FILE_SND = 'Upload sound file';

@Component({
  selector: 'app-topic-phrases',
  templateUrl: './topic-phrases.component.html',
  styleUrls: ['./topic-phrases.component.scss'],
})
export class TopicPhrasesComponent implements OnDestroy {
  @Input() set topic(topic: string) {
    this.topicId = topic;
    this.phrases = [];

    if (this.dbReference) {
      off(this.dbReference);
    }

    this.dbReference = child(this.firebaseService.database('topicPhrases'), topic);
    this.adjectivesDbReference = child(this.firebaseService.database('topicPhraseAdjectives'), topic);

    onValue(this.dbReference, snapshot => {
      const phrases = snapshot.val();
      const phrasesArray = phrases ? Object.keys(phrases).map(id => (({id, ...phrases[id]})) as Phrase) : [];
      this.length = phrasesArray.length;
      this.phrases = phrasesArray;
    });

    this.pageSize = 20;
    this.pageMax = this.pageSize;
    this.pageMin = 0;
    this.formGroup.reset();
  }

  @ViewChild(FormGroupDirective) form: FormGroupDirective;

  formGroup = this.formbuilder.group({
    noun: ['', Validators.required],
    img: [null, Validators.required],
    snd: [null, Validators.required],
    def: ['', Validators.required],
    suffix: [''],
    prefix: [''],
  });

  phrases: Phrase[] = [];
  topicId: string;
  plural = false;

  length = 0;
  pageSize = 20;
  pageSizeOptions = [20, 40, 60];
  pageEvent: PageEvent;
  pageMax = this.pageSize;
  pageMin = 0;

  dbReference: DatabaseReference;
  adjectivesDbReference: DatabaseReference;
  storageReference: StorageReference = this.firebaseService.storage('phrases');

  uploadImgLabel = UPLOAD_FILE_IMG;
  uploadSndLabel = UPLOAD_FILE_SND;

  constructor(
    private firebaseService: FirebaseService,
    private generatorService: GeneratorService,
    private formbuilder: FormBuilder,
    private storageService: StorageService,
  ) {
  }

  ngOnDestroy() {
    off(this.dbReference);
  }

  async editPhrase(phrase: Phrase, mediaType: PhraseMedia, event: Event & { target: HTMLInputElement }) {
    await deleteObject(this.firebaseService.storage('phrases/' + phrase.id + '/' + mediaType))
      .then(() =>
        uploadBytes(storage_ref(
            storage_ref(this.storageReference, phrase.id), mediaType),
          event.target.files && event.target.files[0])
          .then(snapshot => getDownloadURL(snapshot.ref)
            .then(downloadURL => {
                set(
                  this.firebaseService.database('topicPhrases/' + this.topicId + '/' + phrase.id + '/' + mediaType),
                  downloadURL);
                phrase[mediaType] = downloadURL;
              },
            ),
          ),
      );
  }

  async editDefinition(phrase: Phrase) {
    await set(this.firebaseService.database('topicPhrases/' + this.topicId + '/' + phrase.id + '/def'), phrase.def);
  }

  processEventData(event?: PageEvent) {
    this.pageMax = event.pageSize + (event.pageIndex * event.pageSize);
    this.pageMin = (event.pageIndex * event.pageSize);
    return event;
  }

  /**
   * Refreshes the phrase with new wordsForms from morphodita
   */
  async refreshPhrase(phrase: Phrase) {
    const suffix: string = phrase.suffix ?? null;
    const prefix: string = phrase.prefix ?? null;

    let originalNoun = phrase.noun;
    if (suffix && prefix || prefix) {
      originalNoun = phrase.noun.split(' ')[1];
      originalNoun = phrase.noun.split(' ')[1];
    } else if (suffix) {
      originalNoun = phrase.noun.split(' ')[0];
    }

    const wordForms = await this.generatorService.requestMorphodita(originalNoun);
    const isPlural = phrase.wordForms.some(wf => this.generatorService.isPlural(wf.items));

    if (!wordForms?.length) {
      throw Error('No word forms from MorphoDiTa!');
    }

    let noun;
    if (isPlural) {
      this.generatorService.removeSingularForms(wordForms);
      noun = this.getPluralMainNoun(wordForms[0]);
      wordForms[0].lemmaProper = noun;
      this.setCustomLemma(wordForms[0], noun, originalNoun);
    } else {
      noun = originalNoun;
    }

    if (suffix || prefix) {
      noun = this.generatorService.wordAffix(noun, prefix, suffix);
      wordForms[0].lemmaProper = noun;
      this.setCustomLemma(wordForms[0], noun, originalNoun);
    }

    const newPhrase: Phrase = {
      id: phrase.id,
      noun,
      def: phrase.def,
      snd: phrase.snd,
      img: phrase.img,
      txt: phrase.txt,
      suffix,
      prefix,
      adjectives: phrase.adjectives,
      wordForms,
    };

    const databaseReference = this.firebaseService.database(
      'topicPhrases/' + this.topicId + '/' + phrase.id + '/',
    );

    await update(databaseReference, {
      wordForms,
    });

    const index = this.phrases.indexOf(phrase);
    if (index !== -1) {
      this.phrases[index] = newPhrase;
    }

    if (this.form) {
      this.form.resetForm();
    }
  }

  async saveNew() {
    try {
      const wordForms = await this.generatorService.requestMorphodita(this.formGroup.value.noun);
      if (!wordForms?.length) {
        throw Error('No word forms from MorphoDiTa!');
      }

      let noun;
      const suffix: string = this.formGroup.value.suffix;
      const prefix: string = this.formGroup.value.prefix;
      const def: string = this.formGroup.value.def;

      if (this.plural) {
        this.generatorService.removeSingularForms(wordForms);
        noun = this.getPluralMainNoun(wordForms[0]);
        wordForms[0].lemmaProper = noun;
        this.setCustomLemma(wordForms[0], noun, this.formGroup.value.noun);
      } else {
        noun = this.formGroup.value.noun;
      }

      if (suffix || prefix) {
        noun = this.generatorService.wordAffix(noun, prefix, suffix);
        wordForms[0].lemmaProper = noun;
        this.setCustomLemma(wordForms[0], noun, this.formGroup.value.noun);
      }

      const newPhraseDbRef = push(this.dbReference);
      const newPhrase: Phrase = {
        id: newPhraseDbRef.key,
        noun,
        def,
        snd: '',
        img: '',
        txt: '',
        suffix,
        prefix,
        adjectives: [],
        wordForms,
      };
      await set(newPhraseDbRef, {
        noun,
        def,
        suffix,
        prefix,
        wordForms,
      });

      type DataType = 'img' | 'snd';
      await Promise.all(['img', 'snd']
        .map(
          async key => {
            if (!this.form.value[key]) {
              update(newPhraseDbRef, {[key]: ''});
            } else {
              const storageRef = storage_ref(storage_ref(this.storageReference, newPhraseDbRef.key), key);
              const snapshot = await uploadBytes(storageRef, this.formGroup.value[key as DataType] as unknown as Blob);
              const downloadURL = await getDownloadURL(snapshot.ref);
              update(newPhraseDbRef, {[key]: downloadURL});
              newPhrase[key] = downloadURL;
            }
          },
        ));

      if (this.form) {
        this.form.resetForm();
      }
      this.uploadImgLabel = UPLOAD_FILE_IMG;
      this.uploadSndLabel = UPLOAD_FILE_SND;

      // @TODO file inputs are not reset properly, reset them manually
    } catch (err) {
      alert(`Error occured:\n${err.message}`);
      console.error(err);
    }
  }

  onFileChange(formKey: string, event: Event & { target: HTMLInputElement }) {
    if (formKey === 'img') {
      this.uploadImgLabel = event.target.files[0].name;
    } else {
      this.uploadSndLabel = event.target.files[0].name;
    }
    this.formGroup.patchValue({[formKey]: event.target.files && event.target.files[0]});
  }

  countSubItemsLength(outerItems: { items: object[] }[]) {
    return outerItems.reduce((acc, {items}) => acc + items.length, 0);
  }

  getWordType(phrase: Phrase): string {
    return phrase.wordForms[0].items[0].items[0].type;
  }

  async removePhrase(phraseKey: string) {
    const res = confirm('Do you really want to remove this phrase');
    if (!res) {
      return;
    }
    await remove(child(this.dbReference, phraseKey));
    this.removePhraseLocally(phraseKey);
    await this.storageService.removePhraseObjects(phraseKey);
    await remove(child(this.adjectivesDbReference, phraseKey));
  }

  removePhraseLocally(phraseKey: string) {
    const phrase: Phrase = this.phrases.find(p => p.id === phraseKey);
    const index: number = this.phrases.indexOf(phrase);
    if (index === -1) {
      return;
    }

    this.phrases.splice(index, 1);
    this.length--;
  }

  async removeLemmaProper({phraseKey, lemmaProperIndex}: RemoveFormParams) {
    const lemmaDbRef = child(child(this.dbReference, phraseKey), 'wordForms');
    onValue(lemmaDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(lemmaProperIndex, 1);
      if (array.length > 0) {
        await set(lemmaDbRef, array);
        this.removeLemmaProperLocally({phraseKey, lemmaProperIndex});
      } else {
        await this.removePhrase(phraseKey);
      }
    }, {
      onlyOnce: true,
    });
  }

  removeLemmaProperLocally({phraseKey, lemmaProperIndex}: RemoveFormParams) {
    const phrase: Phrase = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms.splice(lemmaProperIndex, 1);
  }

  async removeAddInfo({phraseKey, lemmaProperIndex, addInfoIndex}: RemoveFormParams) {
    const wordFormDbRef = child(
      child(child(child(this.dbReference, phraseKey), 'wordForms'), String(lemmaProperIndex)), 'items');
    onValue(wordFormDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(addInfoIndex, 1);
      if (array.length > 0) {
        await set(wordFormDbRef, array);
        this.removeAddInfoLocally({phraseKey, lemmaProperIndex, addInfoIndex});
      } else {
        await this.removeLemmaProper({phraseKey, lemmaProperIndex});
      }
    }, {
      onlyOnce: true,
    });
  }

  removeAddInfoLocally({phraseKey, lemmaProperIndex, addInfoIndex}: RemoveFormParams) {
    const phrase: Phrase = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms[lemmaProperIndex].items.splice(addInfoIndex, 1);
  }

  async removeWordForm({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex}: RemoveFormParams) {
    const wordFormDbRef = child(
      child(
        child(child(child(child(this.dbReference, phraseKey), 'wordForms'), String(lemmaProperIndex)), 'items'),
        String(addInfoIndex)),
      'items');
    onValue(wordFormDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(wordFormIndex, 1);
      if (array.length > 0) {
        await set(wordFormDbRef, array);
        this.removeWordFormLocally({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex});
      } else {
        await this.removeAddInfo({phraseKey, lemmaProperIndex, addInfoIndex});
      }
    }, {
      onlyOnce: true,
    });
  }

  removeWordFormLocally({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex}: RemoveFormParams) {
    const phrase: Phrase = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms[lemmaProperIndex].items[addInfoIndex].items.splice(wordFormIndex, 1);
  }

  getPluralMainNoun(forms: GroupedWordForms) {
    const allForms = forms.items.reduce((a, b) => a.concat(b.items), []);
    return allForms
      .sort((a, b) => (a.addInfo.length ? 1 : 0) - (b.addInfo.length ? 1 : 0))
      .find(item => item.case === 1 && item.number === 'plural').form;
  }

  setCustomLemma(forms: GroupedWordForms, newLemma: string, oldLemma: string) {
    forms.items.forEach(style => {
      style.items.forEach(form => {
        form.lemma = newLemma;
        form.lemmaRaw = form.lemmaRaw.replace(oldLemma, newLemma);
      });
    });
  }
}
