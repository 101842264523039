<table>
  <tr>
    <th>LemmaProper</th>
    <th>AddInfo</th>
    <th>Form</th>
    <ng-container *ngIf="getWordType !== wordTypes.Adverb">
      <th>Gender</th>
      <th>Number</th>
      <th>Case</th>
    </ng-container>
    <th *ngIf="getWordType === wordTypes.Adverb || getWordType === wordTypes.Adjective">Grade</th>
    <th>Neg.</th>
    <th>Coll.</th>
    <th>Tag</th>
    <th></th>
  </tr>
  <ng-container *ngFor="let lemmaProperColl of phrase.wordForms; let i = index">
    <ng-container *ngFor="let addInfoColl of lemmaProperColl.items; let j = index">
      <tr
        *ngFor="let form of addInfoColl.items; let k = index"
        [ngClass]="'row-' + (i % 2 === 0 ? 'even' : 'odd') + '-' + (j % 2 === 0 ? 'even' : 'odd')">
        <td
          lemma-proper
          *ngIf="j === 0 && k === 0"
          [rowSpan]="countSubItemsLength(lemmaProperColl.items)">
          {{lemmaProperColl.lemmaProper}}
          <br>
          <button
            type="button"
            (click)="removeLemmaProper.emit({phraseKey: phrase.id, lemmaProperIndex: i})">
            Remove
          </button>
        </td>

        <td
          add-info
          *ngIf="k === 0"
          [rowSpan]="addInfoColl.items.length">
          {{ addInfoColl.addInfo }}<br>
          <button
            type="button"
            (click)="removeAddInfo.emit({phraseKey: phrase.id, lemmaProperIndex: i, addInfoIndex: j})">
            Remove
          </button>
        </td>
        <td form>{{ form.form }}</td>

        <ng-container *ngIf="getWordType !== wordTypes.Adverb">
          <td>{{ form.gender }}</td>
          <td>{{ form.number }}</td>
          <td>{{ form.case }}</td>
        </ng-container>

        <td *ngIf="getWordType === wordTypes.Adverb || getWordType === wordTypes.Adjective">
          {{ form.grade }}
        </td>

        <td>
          <mat-icon *ngIf="form.negation" style="color: green">check</mat-icon>
          <mat-icon *ngIf="!form.negation" style="color: red">remove_circle_outline</mat-icon>
        </td>

        <td>
          <mat-icon *ngIf="form.colloquial" style="color: green">check</mat-icon>
          <mat-icon *ngIf="!form.colloquial" style="color: red">remove_circle_outline</mat-icon>
        </td>

        <td>
          <span (click)="openMorphoditaTagStructure()" matTooltipClass="my-tooltip" [matTooltip]="tagHumanized(form)"
                *ngIf="!form.colloquial"
                style="cursor: pointer">{{ form.tag }}</span>
        </td>

        <td>
          <button
            type="button"
            (click)="removeWordForm.emit({phraseKey: phrase.id, lemmaProperIndex: i, addInfoIndex: j, wordFormIndex: k})">
            Remove
          </button>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</table>
