import * as _ from 'lodash';

import {LemmaSequence, Lemmatized, LexicalPunctuation, Phrase, WordForm} from './generator.types';

import jsonData from './../data/common-phrases.json';

const commonPhrases: Record<string, any> = jsonData;

/**
 * Combines array of arrays
 * @param input array
 * @param combinator function verifying compatibility of added value based on previous values
 */
export function combine<T>(input: T[][], combinator?: (last: T, next: T, previous?: T[]) => boolean): T[][] {
  input = [...input]; // ?
  const res: T[][] = [];

  if (!input.length) return [];

  const last = input.pop();
  for (const l of last) {
    const sub = combine(input, combinator);

    if (!sub.length) sub.push([]);

    for (const s of sub) {
      if (!combinator || !s.length || combinator(s.slice(-1)[0], l, s)) {
        s.push(l);
        res.push(s);
      }
    }
  }

  return res;
}

export function sententize(
    segments: string | string[], addPunctuation = true,
    upperFirst = false, punctuation: LexicalPunctuation = '.'): string {
  let res: string;
  if (Array.isArray(segments)) {
    res = segments.join(' ');
  } else {
    res = segments;
  }

  if (upperFirst && res.length > 0) res = res[0].toUpperCase() + res.slice(1);
  if (addPunctuation) return addPunctuationToSentence(res, punctuation);
  return res;
}

export function lemmaSententize(
    sentence: LemmaSequence, addPunctuation = true,
    upperFirst = false, punctuation: LexicalPunctuation = '.'): LemmaSequence {
  if (sentence.length === 0) { return sentence; }

  sentence = sentence.reduce((a, b) => {
    if (a.length === 0) {
      return [b];
    }
    if (typeof a[a.length - 1] === 'string' && typeof b === 'string') {
      a[a.length - 1] = a[a.length - 1].concat(' ', b);
      return a;
    } else {
      return a.concat(b);
    }
  }, []);

  if (upperFirst) {
    if (typeof sentence[0] === 'string') {
      sentence[0] = _.upperFirst(sentence[0] as string);
    } else {
      (sentence[0] as Lemmatized).correct = _.upperFirst((sentence[0] as Lemmatized).correct);
    }
  }

  if (addPunctuation) {
    if (typeof sentence[sentence.length - 1] === 'string') {
      sentence[sentence.length - 1] = addPunctuationToSentence(sentence[sentence.length - 1] as string, punctuation);
    } else {
      sentence.push(punctuation);
    }
  }

  return sentence;
}

export function addPunctuationToSentence(string: string, punctuation: LexicalPunctuation): string {
  if (string.length > 0 && (!string.includes(punctuation) || string.slice(-1) !== punctuation)) {
    string += punctuation;
  }
  return string;
}

export function distinct<T>(input: T[] | Iterable<T>): T[] {
  return Array.from(new Set(input));
}

export function objectDistinct<T>(input: T[]): T[] {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  return _.uniqWith(input, _.isEqual);
}

export function difference<T>(a: T[], b: T[]): T[] {
  return a.filter(i => b.indexOf(i) === -1);
}

export function isOrIncludes<T>(haystack: T[] | T, needle: T): boolean {
  if (Array.isArray(haystack)) {
    return haystack.indexOf(needle) >= 0;
  } else {
    return haystack === needle;
  }
}

export function lemmaDetail(lemma: string): string {
  lemma = lemma.replace(/\^\(.*\)$/, '');
  let detail = ' - ';
  if (lemma.includes(',')) {
    const occurrences = lemma.split(',');
    for (let i = 1; i < occurrences.length; i++) {
      switch (occurrences[i][0]) {
        case 'n':
          detail += 'dialect, ';
          break;
        case 'a':
          detail += 'archaic, ';
          break;
        case 's':
          detail += 'bookish, ';
          break;
        case 'h':
          detail += 'colloquial, ';
          break;
        case 'e':
          detail += 'expressive, ';
          break;
        case 'l':
          detail += 'slang, ';
          break;
        case 'v':
          detail += 'vulgar, ';
          break;
        case 'x':
          detail += 'outdated spelling or misspelling, ';
          break;
      }
    }
  }

  if (lemma.includes(':')) {
    const occurences = lemma.split(':');
    for (let i = 1; i < occurences.length; i++) {
      switch (occurences[i][0]) {
        case 'N':
          detail += 'noun, ';
          break;
        case 'A':
        case 'J':
          detail += 'adjective, ';
          break;
        case 'Z':
          detail += 'pronoun, ';
          break;
        case 'M':
          detail += 'numeral, ';
          break;
        case 'V':
          detail += 'verb, ';
          break;
        case 'W':
          detail += 'perfect, ';
          break;
        case 'T':
          detail += 'imperfect, ';
          break;
        case 'D':
          detail += 'adverb, ';
          break;
        case 'P':
          detail += 'preposition, ';
          break;
        case 'C':
          detail += 'conjunction, ';
          break;
        case 'I':
          detail += 'particle, ';
          break;
        case 'F':
          detail += 'interjection, ';
          break;
        case 'B':
          detail += 'abbreviation, ';
          break;
        case 'X':
          detail += 'do not use, ';
          break;
      }
    }
  }

  if (lemma.includes(';')) {
    const occurences = lemma.split(';');
    for (let i = 1; i < occurences.length; i++) {
      switch (occurences[i][0]) {
        case 'S':
          detail += 'surname, family name, ';
          break;
        case 'E':
          detail += 'member of particular nation, ';
          break;
        case 'G':
          detail += 'geographical name, ';
          break;
        case 'K':
          detail += 'company, organization, ';
          break;
        case 'R':
          detail += 'product, ';
          break;
        case 'm':
          detail += 'other proper name, ';
          break;
        case 'H':
          detail += 'chemistry, ';
          break;
        case 'U':
          detail += 'medicine, ';
          break;
        case 'L':
          detail += 'natural sciences, ';
          break;
        case 'j':
          detail += 'justice, ';
          break;
        case 'g':
          detail += 'technology in general, ';
          break;
        case 'c':
          detail += 'computer and electronics, ';
          break;
        case 'y':
          detail += 'hobby, traveling ';
          break;
        case 'b':
          detail += 'economy, finances ';
          break;
        case 'u':
          detail += 'culture, education ';
          break;
        case 'w':
          detail += 'sports, ';
          break;
        case 'p':
          detail += 'politics, government ';
          break;
        case 'z':
          detail += 'ecology, environment, ';
          break;
        case 'o':
          detail += 'color indication, ';
          break;
      }
    }
  }

  return (detail.length > 3) ? lemma + detail.slice(0, -2) : lemma;
}

export const sortWordForms = (wordForms: WordForm[]): WordForm[] => {
  return wordForms.sort((a, b) => {
    if (a.lemma !== b.lemma) {
      return a.lemma > b.lemma ? +1 : -1;
    }
    if (a.addInfo !== b.addInfo) {
      return a.addInfo > b.addInfo ? +1 : -1;
    }
    if (a.gender !== b.gender) {
      return a.gender > b.gender ? +1 : -1;
    }
    if (a.number !== b.number) {
      return a.number > b.number ? -1 : +1;
    }
    if (a.case !== b.case) {
      return a.case - b.case;
    }
    if (a.negation !== b.negation) {
      return a.negation ? +1 : -1;
    }
    if (a.colloquial !== b.colloquial) {
      return a.colloquial ? +1 : -1;
    }
    return a.form > b.form ? +1 : -1;
  });
};

export function getHelpingVerb(): Phrase {
  return commonPhrases.helpingVerb as Phrase;
}

export function getReflexivePronoun(): Phrase {
  return commonPhrases.reflexivePronoun as Phrase;
}
