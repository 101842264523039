export enum PartOfSpeech {
  Noun = 'noun',
  Adjective = 'adjective',
  Verb = 'verb',
  Adverb = 'adverb',
}

export enum NumberEnum {
  Plural = 'plural',
  Singular = 'singular',
}

export enum Gender {
  Feminine = 'feminine',
  MasculineAnimate = 'masculine animate',
  MasculineInanimate = 'masculine inanimate',
  Neuter = 'neuter',
}

export enum Tense {
  Present = 'present',
  Past = 'past',
  Any = 'any',
  Future = 'future',
  PastOrPresent = 'past or present',
  PresentOrFuture = 'present or future',
}

export enum Grade {
  Positive = 1,
  Comparative = 2,
  Superlative = 3,
}

export enum Person {
  First = 1,
  Second = 2,
  Third = 3,
}

export enum Case {
  Nominative = 1,
  Genitive = 2,
  Dative = 3,
  Accusative = 4,
  Vocative = 5,
  Locative = 6,
  Instrumental = 7,
}

export enum Punctuation {
  Sentence = '.',
  Question = '?',
}

export enum PerfectiveAspect {
  Perfect = 'perfect',
  Imperfect = 'imperfect',
}
