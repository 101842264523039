<h2>Generated sentences:</h2>
<div class="filter">
  <div class="show-panel">
    <label class="generator-label">Show sentences: </label>

    <section class="negation-section">
      <mat-slide-toggle
        class="example-margin"
        [color]="'primary'"
        [(ngModel)]="showSentences">
      </mat-slide-toggle>
    </section>
  </div>

  <div class="show-panel">
    <label class="generator-label">Show questions: </label>

    <section class="negation-section">
      <mat-slide-toggle
        class="example-margin"
        [color]="'primary'"
        [(ngModel)]="showQuestions">
      </mat-slide-toggle>
    </section>
  </div>
</div>

<table class="sentences-table">
  <tr>
    <th colspan="2">Sentences</th>
    <th>Lemmatized</th>
  </tr>
</table>
<div class="sentences-div">
  <table class="sentences-table">
    <tr>
      <td colspan="3" style="text-align: center; font-size: 14pt">Sentences</td>
    </tr>
    <ng-container *ngFor="let sentence of sentences | async; trackBy: trackByIndex">
      <ng-container *ngIf="showSentences">
        <tr>
          <td style="width: 250px">-</td>
          <td colspan="2">
            <button mat-raised-button *ngIf="!published" (click)="remove(sentence)" class="button-x">Remove</button>
            <button mat-raised-button *ngIf="!published" (click)="edit(sentence)" class="button-x">Edit</button>
          </td>
        </tr>

        <tr *ngFor="let answer of sentence.generated[0].correct; let i = index">
          <td *ngIf="i === 0" [rowSpan]="sentence.generated[0].correct.length">Correct</td>
          <td>{{ answer }}</td>

          <td>
            <ng-container *ngFor="let part of sentence.generated[0].lemmatized[i]">
              <ng-container *ngIf="$any(part).lemma; else lemma">
                <label class="correct-form">{{$any(part).correct}}</label>
                <label>({{ $any(part).lemma }})</label>
              </ng-container>
              <ng-template #lemma>
                <label> {{ part }}</label
              ></ng-template>
            </ng-container>
          </td>
        </tr>

        <tr *ngFor="let answer of sentence.generated[0]?.incorrect; let i = index; trackBy: trackByIndex">
          <td
            *ngIf="i === 0"
            [rowSpan]="sentence.generated[0]?.incorrect.length">
            Incorrect
          </td>

          <td>
            <label *ngIf="incorrectSentences[sentence.id] && !published">
              <input
                (change)="changeConfirmedSentences(sentence.id, i, incorrectSentences[sentence.id][i], answer.sentence ? answer.sentence : answer)"
                type="checkbox"
                [(ngModel)]="incorrectSentences[sentence.id][i]">
                Použít? |
            </label>


            {{ answer.sentence ? answer.sentence : answer  }}
          </td>

          <td> – </td>
        </tr>

        <tr>
          <td colspan="3" style="text-align: center">---</td>
        </tr>
      </ng-container>
    </ng-container>

    <tr>
      <td colspan="3" style="text-align: center; font-size: 14pt">Questions</td>
    </tr>

    <ng-container *ngFor="let sentence of questions | async; trackBy: trackByIndex">
      <ng-container *ngIf="showQuestions">
        <tr>
          <td style="width: 250px">Question: {{sentence.generated[0].question}}</td>
          <td colspan="2">
            <button mat-raised-button *ngIf="!published" (click)="remove(sentence)" class="button-x">Remove</button>
            <button mat-raised-button *ngIf="!published" (click)="edit(sentence)" class="button-x">Edit</button>
          </td>
        </tr>

        <tr *ngFor="let answer of sentence.generated[0].correct; let i = index; trackBy: trackByIndex">
          <td
            *ngIf="i === 0"
            [rowSpan]="sentence.generated[0].correct.length">
            Correct
          </td>

          <td>{{ answer }}</td>

          <td>
            <ng-container *ngFor="let part of sentence.generated[0].lemmatized[i]">
              <ng-container *ngIf="$any(part).lemma; else lemma">
                <label class="correct-form">{{ $any(part).correct }}</label>
                <label>
                  ({{$any(part).lemma}})
                </label>
              </ng-container>

              <ng-template #lemma>
                <label>{{part}}</label>
              </ng-template>
            </ng-container>
          </td>
        </tr>

        <tr *ngFor="let answer of sentence.generated[0].incorrect; let i = index">
          <td *ngIf="i === 0" [rowSpan]="sentence.generated[0].incorrect.length">Incorrect</td>
          <td>
            <label *ngIf="incorrectQuestions[sentence.id] && !published">
              <input
                (change)="changeConfirmedQuestions(sentence.id, i, incorrectQuestions[sentence.id][i], answer.sentence ? answer.sentence : answer)"
                [(ngModel)]="incorrectQuestions[sentence.id][i]"
                type="checkbox">
              Použít? |
            </label>

            {{ answer.sentence ? answer.sentence : answer  }}
          </td>
          <td> – </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: center">---</td>
        </tr>
      </ng-container>
    </ng-container>
  </table>
</div>
