import {Component, Input, OnDestroy} from '@angular/core';
import {child, DatabaseReference, off, onValue, push, remove, set, update} from '@angular/fire/database';
import {deleteObject, getDownloadURL, ref as storage_ref, StorageReference, uploadBytes} from '@angular/fire/storage';
import {FormBuilder, Validators} from '@angular/forms';
import {FirebaseService} from '../../firebase.service';
import {GeneratorService} from '../../generator.service';
import {Adjective, GroupedWordForms, Phrase, PhraseMedia} from '../../generator.types';
import {StorageService} from '../../storage.service';

interface RemoveFormParams {
  phraseKey: string;
  lemmaProperIndex?: number;
  addInfoIndex?: number;
  wordFormIndex?: number;
}

@Component({
  selector: 'app-topic-phrase-adjectives',
  templateUrl: './topic-phrase-adjectives.component.html',
  styleUrls: ['./topic-phrase-adjectives.component.scss'],
})
export class TopicPhraseAdjectivesComponent implements OnDestroy {
  phrases: Adjective[] = [];
  dbReference: DatabaseReference;
  storageReference: StorageReference = this.firebaseService.storage('phrases');

  @Input() parentPhrase: Phrase;

  @Input() set topicPhrase(topicPhrase: string) {
    if (this.dbReference) {
      off(this.dbReference);
    }
    this.dbReference = child(this.firebaseService.database('topicPhraseAdjectives'), topicPhrase);

    onValue(this.dbReference, snapshot => {
      const phrases = snapshot.val();
      this.phrases = phrases ? Object.keys(phrases).map(id => ({id, ...phrases[id]}) as Adjective) : [];
    });
  }

  form = this.formbuilder.group({
    word: ['', Validators.required],
    img: [''],
    snd: [''],
    def: [''],
  });

  constructor(
    private firebaseService: FirebaseService,
    private generatorService: GeneratorService,
    private formbuilder: FormBuilder,
    private storageService: StorageService) {
  }

  ngOnDestroy() {
    off(this.dbReference);
  }

  async editPhrase(phrase: Adjective, mediaType: PhraseMedia, event: Event & {target: HTMLInputElement}) {
    const storageRef = this.firebaseService.storage('phrases/' + phrase.id + '/' + mediaType);
    const dbRef = child(child(this.dbReference, phrase.id), mediaType);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    await deleteObject(storageRef).then(() => uploadBytes(storageRef, event.target.files && event.target.files[0])
        .then(snapshot => getDownloadURL(snapshot.ref)
          .then(downloadURL => {
            set(dbRef, downloadURL);
            phrase[mediaType] = downloadURL;
          }),
        ),
      ),
    uploadBytes(storageRef, event.target.files && event.target.files[0])
      .then(snapshot => getDownloadURL(snapshot.ref)
        .then(downloadURL => {
          set(dbRef, downloadURL);
          phrase[mediaType] = downloadURL;
        }),
      );
  }

  async editDefinition(phrase: Adjective) {
    const phraseDbRef = child(child(this.dbReference, phrase.id), 'def');
    await set(phraseDbRef, phrase);
  }

  async saveNew() {
    try {
      let wordForms = await this.generatorService.requestMorphodita(this.form.value.word);

      // include only genders that are present in the parent phrase:
      const parentGenders = new Set<string>();
      this.parentPhrase.wordForms
        .forEach(form => form.items
          .forEach(subform => subform.items
            .forEach(item => parentGenders.add(item.gender))));

      wordForms = wordForms
        .map((form: GroupedWordForms) => {
          form.items = form.items
            .map(subform => {
              subform.items = subform.items.filter(({gender}) => parentGenders.has(gender));
              return subform;
            })
            .filter(({items}) => items.length > 0);
          return form;
        })
        .filter(({items}: GroupedWordForms) => items.length > 0);

      const newPhraseDbRef = push(this.dbReference);
      const newPhrase: Adjective = {
        id: newPhraseDbRef.key,
        phraseId: this.parentPhrase.id,
        word: this.form.value.word,
        def: this.form.value.def,
        snd: '',
        img: '',
        wordForms,
      };
      await set(newPhraseDbRef, {
        word: this.form.value.word,
        def: this.form.value.def = this.form.value.def || '',
        wordForms,
      });

      type DataType = 'img' | 'snd';
      await Promise.all(['img', 'snd']
        .map(
          async (key: any) => {
            if (!this.form.value[key as DataType]) {
              update(newPhraseDbRef, {[key]: ''});
            } else {
              const storageRef = storage_ref(storage_ref(this.storageReference, newPhraseDbRef.key), key);
              const snapshot = await uploadBytes(storageRef, this.form.value[key as DataType] as unknown as Blob);
              const downloadURL = await getDownloadURL(snapshot.ref);
              update(newPhraseDbRef, {[key]: downloadURL});
              newPhrase[key] = downloadURL;
            }
          },
        ));
      this.phrases.push(newPhrase);
      this.form.reset();
      // @TODO file inputs are not reset properly, reset them manually
    } catch (err) {
      alert(`Error occured:\n${err.message}`);
      console.error(err);
    }
  }

  onFileChange(formKey: string, event: Event & {target: HTMLInputElement}) {
    this.form.patchValue({[formKey]: event.target.files && event.target.files[0]});
  }

  countSubItemsLength(outerItems: {items: object[]}[]) {
    return outerItems.reduce((acc, {items}) => acc + items.length, 0);
  }

  async removePhrase(phraseKey: string) {
    await remove(child(this.dbReference, phraseKey));
    this.removePhraseLocally(phraseKey);
    await this.storageService.removePhraseObjects(phraseKey);
  }

  removePhraseLocally(phraseKey: string) {
    const phrase: Adjective = this.phrases.find(p => p.id === phraseKey);
    const index: number = this.phrases.indexOf(phrase);
    this.phrases.splice(index, 1);
  }

  async removeLemmaProper({phraseKey, lemmaProperIndex}: RemoveFormParams) {
    const lemmaDbRef = child(child(this.dbReference, phraseKey), 'wordForms');
    onValue(lemmaDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(lemmaProperIndex, 1);
      if (array.length > 0) {
        await set(lemmaDbRef, array);
        this.removeLemmaProperLocally({phraseKey, lemmaProperIndex});
      } else {
        await this.removePhrase(phraseKey);
      }
    }, {
      onlyOnce: true,
    });
  }

  removeLemmaProperLocally({phraseKey, lemmaProperIndex}: RemoveFormParams) {
    const phrase: Adjective = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms.splice(lemmaProperIndex, 1);
  }

  async removeAddInfo({phraseKey, lemmaProperIndex, addInfoIndex}: RemoveFormParams) {
    const wordFormDbRef = child(
      child(child(child(this.dbReference, phraseKey), 'wordForms'), String(lemmaProperIndex)), 'items');
    onValue(wordFormDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(addInfoIndex, 1);
      if (array.length > 0) {
        await set(wordFormDbRef, array);
        this.removeAddInfoLocally({phraseKey, lemmaProperIndex, addInfoIndex});
      } else {
        await this.removeLemmaProper({phraseKey, lemmaProperIndex});
      }
    }, {
      onlyOnce: true,
    });
  }

  removeAddInfoLocally({phraseKey, lemmaProperIndex, addInfoIndex}: RemoveFormParams) {
    const phrase: Adjective = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms[lemmaProperIndex].items.splice(addInfoIndex, 1);
  }

  async removeWordForm({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex}: RemoveFormParams) {
    const wordFormDbRef = child(
      child(
        child(child(child(child(this.dbReference, phraseKey), 'wordForms'), String(lemmaProperIndex)), 'items'),
        String(addInfoIndex)),
      'items');
    onValue(wordFormDbRef, async snapshot => {
      const array = snapshot.val();
      array.splice(wordFormIndex, 1);
      if (array.length > 0) {
        await set(wordFormDbRef, array);
        this.removeWordFormLocally({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex});
      } else {
        await this.removeAddInfo({phraseKey, lemmaProperIndex, addInfoIndex});
      }
    }, {
      onlyOnce: true,
    });
  }

  removeWordFormLocally({phraseKey, lemmaProperIndex, addInfoIndex, wordFormIndex}: RemoveFormParams) {
    const phrase: Adjective = this.phrases.find(p => p.id === phraseKey);
    phrase.wordForms[lemmaProperIndex].items[addInfoIndex].items.splice(wordFormIndex, 1);
  }
}
