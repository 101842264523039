import {Component, Input, OnDestroy} from '@angular/core';
import {child, DatabaseReference, off, onValue} from '@angular/fire/database';
import {Subject} from 'rxjs';
import {FirebaseService} from '../../firebase.service';

@Component({
  selector: 'app-preview-phrases',
  templateUrl: './preview-phrases.component.html',
  styleUrls: ['./preview-phrases.component.scss'],
})
export class PreviewPhrasesComponent implements OnDestroy {
  topicId: string;
  phrases = new Subject<any[]>();

  dbReference: DatabaseReference;
  @Input() set topic(topic: string) {
    this.topicId = topic;

    this.phrases.next([]);

    if (this.dbReference) {
      off(this.dbReference);
    }

    this.dbReference = child(this.firebaseService.database('generatedTopics/phrases'), this.topicId);

    onValue(this.dbReference, snapshot => {
      const phrases = snapshot.val();
      const phrasesArray = phrases ? Object.keys(phrases).map(id => ({id, ...phrases[id]})) : [];
      this.phrases.next(phrasesArray);
    });
  }

  constructor(
    private firebaseService: FirebaseService,
  ) { }

  ngOnDestroy() {
    off(this.dbReference);
  }
}
