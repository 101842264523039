import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {child, DatabaseReference, get, off, onValue, push, remove, set} from '@angular/fire/database';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {BehaviorSubject, debounceTime, Subject, tap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FirebaseService} from '../../firebase.service';
import {Case, NumberEnum, Punctuation, Tense} from '../../generator.constants';
import {GeneratorService} from '../../generator.service';
import {
  Adjective, FirebaseStatement,
  LexicalPunctuation,
  Phrase,
  QAs,
  Question,
  Sentence,
  Topic,
  WordDb,
  WordGenerator,
} from '../../generator.types';

@Component({
  selector: 'app-sentences',
  templateUrl: './sentences.component.html',
  styleUrls: ['./sentences.component.scss'],
})
export class SentencesComponent implements OnInit, OnDestroy {
  @Input() set topic(topic: string) {
    this.topicId = topic;
    if (this.phrasesDbReference) {
      off(this.phrasesDbReference);
    }
    if (this.adjectivesDbReference) {
      off(this.adjectivesDbReference);
    }

    this.phrasesDbReference = child(this.firebaseService.database('topicPhrases'), topic);
    this.adjectivesDbReference = child(this.firebaseService.database('topicPhraseAdjectives'), topic);

    onValue(this.phrasesDbReference, snapshot => {
      const phrases = snapshot.val();
      this.phrasesArray = phrases ? Object.keys(phrases).map(id => ({id, ...phrases[id]}) as Phrase) : [];

      this.processPhrasesAndAdjectives();
    });
    onValue(this.adjectivesDbReference, snapshot => {
      const adjectivesOfPhrases = snapshot.val();
      this.adjectivesArray = [];

      if (adjectivesOfPhrases) {
        Object.keys(adjectivesOfPhrases).forEach(phraseId => Object.keys(adjectivesOfPhrases[phraseId]).forEach(id =>
          this.adjectivesArray.push(({phraseId, id, ...adjectivesOfPhrases[phraseId][id]}) as Adjective),
        ));
      }

      this.processPhrasesAndAdjectives();
    });
  }

  @Input() editSentence = new BehaviorSubject<any>(null);

  cancel$ = new Subject();
  questionExists$ = new Subject();
  phrasesDbReference: DatabaseReference;
  adjectivesDbReference: DatabaseReference;
  phrasesArray: Phrase[] = [];
  adjectivesArray: Adjective[] = [];
  wordDb: WordDb;
  question: Question;
  forms: string[] = [];
  topicId: string;
  morphedForms: QAs[];
  sentenceToGenerate: any = {generator: [], punctuation: null};

  punctuation: LexicalPunctuation = null;
  punctuations = [Punctuation.Sentence, Punctuation.Question];

  nestPart = false;
  updateStatement = false;
  updateStatementId: string;
  isUpdatedQuestion = false;
  validGenerator = true;
  generatorError = '';

  rows: boolean[][] = [];
  activeRow: number[] = [0, 0];
  addBelow = true;

  questionAlreadyExists = false;
  checkingExistingQuestion = false;

  constructor(
    private firebaseService: FirebaseService,
    private generatorService: GeneratorService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.pipe(
      takeUntil(this.cancel$),
    ).subscribe(__ => this.ngOnDestroy());

    this.questionExists$.pipe(
      tap(() => this.checkingExistingQuestion = Boolean(this.question?.question)),
      debounceTime(500)
    ).subscribe(async () => {
      let question = this.question?.question;
      if (!question) {
        return;
      }

      let prepareText = (text: string) => text.toLowerCase().replace('?', '').trim();
      let questionsSnapshot = await get(child(this.firebaseService.database('topicQuestions'), this.topicId));
      this.questionAlreadyExists = Object.values(questionsSnapshot.val() as FirebaseStatement[] ?? [])
        .map(any => prepareText(any.question))
        .includes(prepareText(question));
      this.checkingExistingQuestion = false;
    });

    this.editSentence.pipe(
      takeUntil(this.cancel$),
    ).subscribe(sentence => {
      if (sentence) {
        this.updateStatementId = sentence.id;
        if (sentence.question) {
          this.isUpdatedQuestion = true;
          this.sentenceToGenerate.generator = sentence.answer[0].generator;
          this.question = {
            question: sentence.question,
            answer: [],
          };
        } else {
          this.isUpdatedQuestion = false;
          this.sentenceToGenerate.generator = sentence.generator;
        }
        this.punctuation = sentence.punctuation ? sentence.punctuation : null;
        this.validateStatement();
        this.updateActiveRows();
        this.updateStatement = true;
      }
    });
  }

  ngOnDestroy() {
    this.cancel$.next(void 0);
    this.cancel$.complete();
    this.resetForm();
  }

  onQuestionInputKeyUp() {
    this.questionExists$.next(void 0);
  }

  changeActiveRows(indexI: number, indexJ: number) {
    this.negateActiveRows();
    this.rows[indexI][indexJ] = true;
    this.activeRow = [indexI, indexJ];
  }

  updateActiveRows() {
    if (this.sentenceToGenerate) {
      const partsLength = this.sentenceToGenerate.generator.length;
      this.negateActiveRows();

      this.rows[partsLength - 1][this.rows[partsLength - 1].length - 1] = true;
      this.activeRow = [partsLength - 1, this.rows[partsLength - 1].length - 1];
    }
  }

  negateActiveRows() {
    this.rows = [];
    this.activeRow = [0, 0];
    const partsLength = this.sentenceToGenerate.generator.length;

    for (let i = 0; i < partsLength; i++) {
      this.sentenceToGenerate.generator[i].forEach(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.rows[i] ? this.rows[i].push(false) : this.rows[i] = [false];
      });
    }
  }

  processPhrasesAndAdjectives() {
    this.phrasesArray.forEach(phrase => {
      phrase.adjectives = this.adjectivesArray
        .filter(phraseAdjectives => phraseAdjectives.phraseId === phrase.id)

        .map(adjective => {
          return adjective.word;
        });
    });

    this.wordDb = this.generatorService.compileWords([
      ...this.phrasesArray, ...this.adjectivesArray,
    ]);
  }

  addQuestion() {
    this.question = {
      question: '',
      answer: [],
    };
  }

  removeQuestion() {
    this.question = null;
  }

  addSimpleText() {
    this.addIt({generator: ['']});
  }

  addNewPhrase() {
    const generator: Sentence = {
      generator: {
        phrase: null,
        case: Case.Nominative,
        gender: undefined,
        person: undefined,
        tense: Tense.Present,
        number: NumberEnum.Singular,
        negation: false,
        perfectiveAspect: undefined,
        acceptedForm: [],
      },
    };

    this.addIt(generator);
  }

  addIt(value: Sentence) {
    const appender = this.addBelow ? 1 : 0;
    let firstElement = false;
    if (!this.sentenceToGenerate.generator.length) {
      firstElement = true;
      this.nestPart = false;
    }

    if (this.nestPart) {
      this.sentenceToGenerate.generator[this.activeRow[0]].splice(this.activeRow[1] + appender, 0, value);
      if (this.activeRow) {
        this.activeRow[1]++;
        this.changeActiveRows(this.activeRow[0], this.activeRow[1]);
      }
    } else {
      this.sentenceToGenerate.generator.splice(this.activeRow[0] + appender, 0, [value]);
      if (this.activeRow) {
        if (!firstElement) {
          this.activeRow[0]++;
        }
        this.changeActiveRows(this.activeRow[0], 0);
      }
    }
  }

  addPunctuation() {
    this.punctuation = '.';
  }

  removePunctuation() {
    this.punctuation = null;
  }

  generateNew() {
    if (!this.validateStatement()) return;
    this.updateStatement = false;
    this.generate();
  }

  generate() {
    if (!this.validateStatement()) return;

    if (this.updateStatement) {
      remove(
        child(
          child(
            this.firebaseService.database(this.isUpdatedQuestion ? 'topicQuestions' : 'topicSentences'),
            this.topicId,
          ),
          this.updateStatementId,
        ),
      );
    }

    let ref;
    let statement;
    const topic: Topic = {phrases: this.phrasesArray, sentences: [], questions: []};

    this.sentenceToGenerate.punctuation = this.punctuation;
    if (this.question) {
      ref = push(child(this.firebaseService.database('topicQuestions'), this.topicId));
      this.question.answer = [this.sentenceToGenerate];
      topic.questions.push(this.question);
      statement = _.cloneDeep(this.question);
    } else {
      ref = push(child(this.firebaseService.database('topicSentences'), this.topicId));
      topic.sentences.push(this.sentenceToGenerate);
      statement = _.cloneDeep(this.sentenceToGenerate);
    }
    const generated = this.generatorService.compileSentences(topic, this.wordDb);
    set(ref, {...statement, generated});

    this.resetForm();
  }

  validateStatement(): boolean {
    this.validGenerator = true;
    this.generatorError = '';
    this.sentenceToGenerate.generator.forEach((part: Sentence, index: number) => {
      if (Array.isArray(part)) {
        part.forEach((subPart: Sentence) => {
          this.formsPreview(subPart.generator as WordGenerator, index);
        });
      }
    });

    return this.validGenerator;
  }

  resetForm() {
    this.sentenceToGenerate = {generator: []};
    this.morphedForms = undefined;
    this.question = null;
    this.removePunctuation();
  }

  formsPreviewEnhance(statement: WordGenerator, index: number = null) {
    this.validGenerator = true;
    this.generatorError = '';
    this.formsPreview(statement, index);
  }

  formsPreview(statement: WordGenerator, index: number = null) {
    this.morphedForms = null;
    let sentences: Sentence[];
    if (index && index !== 0) {
      // NOTE: enforce that generator is not first is important for helping verb position
      sentences = [{generator: [[{generator: ''}], [{generator: _.cloneDeep(statement)}]]}] as Sentence[];
    } else {
      sentences = [{generator: _.cloneDeep(statement)}] as Sentence[];
    }

    try {
      this.morphedForms = this.generatorService.compileSentences(
        {phrases: this.phrasesArray, sentences, questions: []},
        this.wordDb,
        true,
      );
      if (this.morphedForms[0].correct.length === 0) {
        this.validGenerator = false;
      }

    } catch (e) {
      this.validGenerator = false;
      this.generatorError = e.toString();
    }
  }

  removeItem(partIndex: number, subPartIndex?: number) {
    if (subPartIndex !== undefined) {
      this.sentenceToGenerate.generator[partIndex].splice(subPartIndex, 1);
    } else {
      this.sentenceToGenerate.generator.splice(partIndex, 1);
    }

    if (this.sentenceToGenerate.generator.length === 0) {
      this.negateActiveRows();
    } else {
      this.updateActiveRows();
    }
  }

  setActiveDirection(value: boolean) {
    this.addBelow = value;
  }
}
