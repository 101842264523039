import {NumberEnum, PartOfSpeech} from './generator.constants';
import {
  LexicalCase,
  LexicalGender,
  LexicalGrade,
  LexicalNumber,
  LexicalPerson,
  LexicalTense,
  LexicalType,
  LexicalVariant,
} from './generator.types';

export const getPartOfSpeech = (form: string): LexicalType | null => {
  switch (form[0]) {
    case 'N':
      return PartOfSpeech.Noun;
    case 'A':
      return PartOfSpeech.Adjective;
    case 'V':
      return PartOfSpeech.Verb;
    case 'D':
      return PartOfSpeech.Adverb;
    default:
      return null;
  }
};

export const getGender = (form: string): LexicalGender | null => {
  switch (form[2]) {
    case 'F':
      return 'feminine';
    case 'H':
      return 'feminine or neuter';
    case 'Q':
      return 'feminine (with singular only) or neuter (with plural only)';
    case 'M':
      return 'masculine animate';
    case 'Y':
      return 'masculine (either animate or inanimate)';
    case 'I':
      return 'masculine inanimate';
    case 'T':
      return 'masculine inanimate or feminine (plural only)';
    case 'N':
      return 'neuter';
    case 'X':
      return 'any of the basic four genders';
    default:
      return null;
  }
};

export const getNumber = (form: string): LexicalNumber | null => {
  switch (form[3]) {
    case 'S':
      return NumberEnum.Singular;
    case 'P':
      return NumberEnum.Plural;
    default:
      return null;
  }
};

export const getCase = (form: string): LexicalCase | null => {
  const nr = Number(form[4]);
  switch (nr) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return nr;
    default:
      return null;
  }
};

export const getPerson = (form: string): LexicalPerson => {
  const lp = parseInt(form[7], 10);
  switch (lp) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    default:
      return null;
  }
};

export const getTense = (form: string): LexicalTense => {
  // NOTE: Morphodita sending wrong data,
  // second position is B which means "verb, present or future form",
  // when ninth position is P as "present", they can`t use it
  // for future tense, thats why we setup new Lexical Tense
  if (form[1] === 'B' && form[8] !== 'F') return 'present or future';
  switch (form[8]) {
    case 'F':
      return 'future';
    case 'H':
      return 'past or present';
    case 'P':
      return 'present';
    case 'R':
      return 'past';
    case 'X':
      return 'any';
    default:
      return null;
  }
};

export const getVariant = (form: string): LexicalVariant => {
  const lv = parseInt(form[14], 10);
  switch (lv) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    default:
      return null;
  }
};

export const getGrade = (form: string): LexicalGrade | null => {
  const gr = Number(form[9]);
  switch (gr) {
    case 1:
    case 2:
    case 3:
      return gr;
    default:
      return null;
  }
};

export const getColloquial = (form: string): boolean => {

  switch (form[14]) {
    case '-':
    case '1':
      return false;
    default:
      return true;
  }
};

export const getNegation = (form: string): boolean => {
  switch (form[10]) {
    case 'N':
      return true;
    case 'P':
    default:
      return false;
  }
};
// For more info about tag: https://ufal.mff.cuni.cz/pdt2.0/doc/manuals/en/m-layer/html/ch02s02s01.html
export const isUnwantedForm = (tag: string): boolean => {
  // no variant or variant 1.
  const variant = parseInt(tag[14], 10);
  if (variant) {
    return false;
  }

  // No passives
  const voice = tag[11];
  if (voice === 'P') {
    return false;
  }

  // remove specific forms
  // e - Verb, transgressive present (endings -e/-ě, -íc, -íce)
  // s - Verb, past participle, passive (including forms with the enclitic -s, lit. 're (are))
  const unwantedDetailedPartOfSpeech = ['e', 's'];
  const detailedPartOfSpeech = tag[1];
  if (unwantedDetailedPartOfSpeech.includes(detailedPartOfSpeech)) {
    return false;
  }

  // Sometimes reserved tags are used to describe detailed the form
  const reservedDetailedPartOfSpeech = tag[13];
  if (unwantedDetailedPartOfSpeech.includes(reservedDetailedPartOfSpeech)) {
    return false;
  }

  return true;
};

