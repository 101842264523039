<div *ngIf="statement" class="statement-container">
  <ng-container *ngIf="!newPhrase">
    <mat-form-field *ngIf="statement.phrase" class="input-form-180">
      <input matInput value="" placeholder="Phrase" [(ngModel)]="statement.phrase" readonly>
      <mat-hint align="start" *ngIf="!validPhrase" class="error-hint">No correct forms</mat-hint>
    </mat-form-field>
    <button type="button" mat-button (click)="changePhrase()" class="edit-button" matTooltip="Change phrase">
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="newPhrase">
    <mat-form-field>
      <mat-select (selectionChange)="setPhrase()" [(value)]="selectedWord" placeholder="Phrase">
        <mat-option *ngFor="let word of phrasesArray" [value]="word" >
          {{ word.noun + ' - ' + word.def }}
        </mat-option>
      </mat-select>
      <mat-hint align="start" *ngIf="!validPhrase" class="error-hint">No correct forms</mat-hint>
    </mat-form-field>
    <mat-form-field *ngIf="wordForms && wordForms.length > 1">
      <mat-select placeholder="Forma" class="select" (selectionChange)="setForm()" [(value)]="selectedForm">
        <mat-option *ngFor="let form of wordForms" [value]="form" >
          {{ getInfo(form.addInfo) + ' (' + form.items.length + ')' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="showParameters">
    <!-- verb -->
    <ng-container *ngIf="wordType === wordTypes.Verb">
      <mat-form-field class="input-form-60">
        <input
          matInput
          value=""
          placeholder="Prefix"
          [(ngModel)]="statement.prefix"
          [disabled]="lockPrefix">
      </mat-form-field>

      <mat-form-field class="input-form-60">
        <input
          matInput
          value=""
          placeholder="Suffix"
          [(ngModel)]="statement.suffix"
          [disabled]="lockSuffix">
      </mat-form-field>

      <mat-form-field class="input-form-120">
        <mat-select placeholder="Tense" (selectionChange)="formsPreview()" [(ngModel)]="statement.tense">
          <mat-option *ngFor="let tense of tenses" [value]="tense">
            {{ tense }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="showPerfectiveAspect(wordType, statement.tense)">
      <mat-form-field class="input-form-100">
        <mat-select placeholder="Perfective Aspect" (selectionChange)="formsPreview()" [(ngModel)]="statement.perfectiveAspect">
          <mat-option></mat-option>
          <mat-option *ngFor="let perfectiveAspect of perfectiveAspects" [value]="perfectiveAspect">
            {{ perfectiveAspect }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="isInflectional(wordType)" class="input-form-60">
      <mat-select placeholder="Case" (selectionChange)="formsPreview()" [(ngModel)]="statement.case">
        <mat-option></mat-option>
        <mat-option *ngFor="let case of cases" [value]="case">
          {{ case }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="isInflectional(wordType) || isVerb(wordType)" class="input-form-100" >
      <mat-select placeholder="Number" (selectionChange)="formsPreview()" [(ngModel)]="statement.number">
        <mat-option *ngFor="let number of numbers" [value]="number">
          {{ number }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-form-120" *ngIf="isVerb(wordType) || wordType === wordTypes.Adjective">
      <mat-select placeholder="Gender" (selectionChange)="formsPreview()" [(ngModel)]="statement.gender">
        <mat-option></mat-option>
        <mat-option *ngFor="let gender of genders" [value]="gender">
          {{ gender }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="isVerb(wordType)">
      <mat-form-field class="input-form-60">
        <mat-select placeholder="Person" (selectionChange)="formsPreview()" [(ngModel)]="statement.person">
          <mat-option></mat-option>
          <mat-option *ngFor="let person of persons" [value]="person">
            {{ person }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="isVerb(wordType)">
      <mat-form-field class="input-form-60">
        <mat-select placeholder="Reflexive Pronoun" (selectionChange)="formsPreview()" [(ngModel)]="statement.reflexivePronoun">
          <mat-option></mat-option>
          <mat-option *ngFor="let reflexivePronoun of reflexivePronouns" [value]="reflexivePronoun">
            {{ reflexivePronoun }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- adverb -->
    <ng-container *ngIf="hasGrade(wordType)">
      <mat-form-field class="input-form-60">
        <mat-select placeholder="Grade" (selectionChange)="formsPreview()" [(ngModel)]="statement.grade">
          <mat-option>– No grade</mat-option>
          <mat-option *ngFor="let grade of grades" [value]="grade">
            {{ grade }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="wordType === wordTypes.Noun" class="input-form-180">
      <mat-select placeholder="Accepted Form" (selectionChange)="formsPreview()" [(ngModel)]="statement.acceptedForm" multiple>
        <mat-option *ngFor="let af of acceptedForms" [value]="af">
          {{ af }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </ng-container>

</div>
