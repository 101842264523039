import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {Case, Gender, Grade, NumberEnum, PartOfSpeech, PerfectiveAspect, Person, Tense} from '../../../generator.constants';
import {GeneratorService} from '../../../generator.service';
import {ItemInfo, LexicalTense, Phrase, QAs, WordDb, WordForm, WordGenerator} from '../../../generator.types';
import {lemmaDetail} from '../../../generator.utils';

@Component({
  selector: 'app-statement-edit',
  templateUrl: 'statement-edit.component.html',
  styleUrls: ['./statement-edit.component.scss'],
})
export class StatementEditComponent implements OnInit {

  @Input() statement: WordGenerator;
  @Input() phrasesArray: Phrase[];
  @Input() wordDb: WordDb;

  genders = [Gender.Feminine, Gender.MasculineAnimate, Gender.MasculineInanimate, Gender.Neuter];
  numbers = [NumberEnum.Singular, NumberEnum.Plural];
  persons = [1, 2, 3];
  tenses = [Tense.Present, Tense.Past, Tense.Future, Tense.PastOrPresent];
  cases = [1, 2, 3, 4, 5, 6, 7];
  negation = false;
  acceptedForms = ['noun', 'adjective', 'adjective noun'];
  grades = [1, 2, 3];
  perfectiveAspects = [PerfectiveAspect.Perfect, PerfectiveAspect.Imperfect];
  reflexivePronouns: string[] = [];

  newPhrase = false;
  showParameters = true;

  selectedWord: Phrase;
  lemma = '';
  wordForms: ItemInfo[];
  selectedForm: ItemInfo;
  morphedForms: QAs[];
  wordType = '';
  validPhrase = true;
  lockSuffix = false;
  lockPrefix = false;
  wordTypes = PartOfSpeech;

  constructor(private generatorService: GeneratorService) {
    this.generatorService.reflexivePronoun.wordForms[0].items[0].items.map((f: WordForm) => {
      this.reflexivePronouns.push(f.form);
    });
  }

  ngOnInit(): void {
    if (!this.statement.phrase) {
      this.changePhrase();
    }

    this.wordType = this.statement.tense ? PartOfSpeech.Verb : PartOfSpeech.Noun;
    if (this.statement.grade) {
      if (this.statement.case) this.wordType = PartOfSpeech.Adjective;
      else this.wordType = PartOfSpeech.Adverb;
    }
  }

  getInfo(addInfo: string) {
    const formInfo = lemmaDetail(addInfo);
    if (!formInfo) { return 'no suffix'; }
    return formInfo;
  }

  changePhrase() {
    this.newPhrase = true;
    this.showParameters = false;
    this.statement.phrase = null;
    this.statement.acceptedForm = [PartOfSpeech.Noun];
    this.statement.negation = false;
    this.statement.person = null;
    this.statement.gender = null;
    this.statement.number = NumberEnum.Singular;
    this.statement.case = Case.Nominative;
    this.statement.tense = null;
    this.statement.perfectiveAspect = null;
    this.statement.reflexivePronoun = null;
    this.formsPreview();
  }

  setPhrase() {
    this.wordForms = undefined;
    this.selectedForm = undefined;
    this.lemma = '';
    this.wordType = '';

    this.wordForms = this.selectedWord.wordForms
      .map(wf => wf.items).reduce((a, b) => a.concat(b), []);
    if (this.wordForms.length === 1) {
      this.selectedForm = this.wordForms[0];
      this.setForm();
    }
  }

  setForm() {
    this.lemma = this.selectedForm.items[0].lemmaRaw;
    this.wordType = this.selectedForm.items[0].type;
    this.showParameters = true;
    this.newPhrase = false;

    this.statement.phrase = this.lemma;
    this.statement.gender = null;

    switch (this.wordType) {
      case PartOfSpeech.Verb:
        this.handleAffixes(this.selectedWord);
        const prefix = this.selectedWord.prefix ? this.selectedWord.prefix : '';
        const suffix = this.selectedWord.suffix ? this.selectedWord.suffix : '';

        this.statement.acceptedForm = null;
        this.statement.negation = false;
        this.statement.person = Person.First;
        this.statement.number = NumberEnum.Singular;
        this.statement.tense = Tense.Present;
        this.statement.case = null;
        this.statement.suffix = suffix;
        this.statement.prefix = prefix;
        this.statement.grade = null;
        this.statement.perfectiveAspect = null;
        this.statement.reflexivePronoun = null;
        break;

      case PartOfSpeech.Noun:
        this.statement.acceptedForm = [PartOfSpeech.Noun];
        this.statement.number = NumberEnum.Singular;
        this.statement.case = Case.Nominative;
        this.statement.grade = null;
        this.resetVerbCommon();
        break;

      case PartOfSpeech.Adverb:
        this.statement.acceptedForm = [PartOfSpeech.Adverb];
        this.statement.grade = Grade.Positive;
        this.statement.case = null;
        this.statement.number = null;
        this.resetVerbCommon();
        break;

      case PartOfSpeech.Adjective:
        this.statement.acceptedForm = [PartOfSpeech.Adjective];
        this.statement.grade = Grade.Positive;
        this.statement.number = NumberEnum.Singular;
        this.statement.case = Case.Nominative;
        this.statement.gender = Gender.MasculineAnimate;

        this.resetVerbCommon();
        break;
    }

    if (this.generatorService.isPlural(this.wordForms)) this.statement.number = NumberEnum.Plural;
    this.formsPreview();
  }

  formsPreview() {
    this.validPhrase = true;
    this.morphedForms = this.generatorService.compileSentences(
      {
        phrases: this.phrasesArray,
        sentences: [{
          generator: _.cloneDeep(this.statement),
        }],
        questions: [],
      },
      this.wordDb,
    );

    this.validPhrase = this.morphedForms[0].correct.length !== 0;
  }

  hasGrade(wordType: string): boolean {
    return wordType === PartOfSpeech.Adjective || wordType === PartOfSpeech.Adverb;
  }

  isInflectional(wordType: string): boolean {
    return wordType === PartOfSpeech.Noun || wordType === PartOfSpeech.Adjective;
  }
  isVerb(wordType: string): boolean {
    return wordType === PartOfSpeech.Verb;
  }

  showPerfectiveAspect(wordType: string, tense: LexicalTense): boolean {
    return wordType === PartOfSpeech.Verb && tense === 'future';
  }

  private handleAffixes(word: Phrase) {
    this.lockPrefix = word.prefix ? word.prefix.length > 0 : false;
    this.lockSuffix = false;
  }

  private resetVerbCommon() {
    this.statement.tense = null;
    this.statement.negation = null;
    this.statement.person = null;
  }
}
