
<app-topic-settings class="mb-1" [topicId]="selectedTopicId"></app-topic-settings>

<div class="content" *ngIf="selectedTopicId">
  <ng-container>
    <mat-tab-group
      animationDuration="0ms"
      (selectedIndexChange)="onTabChange($any($event))"
      #tabsRef>
      <!-- Tab Phrases -->
      <mat-tab label="Phrases">
        <app-topic-phrases
          *ngIf="activeTab === 0"
          [topic]="selectedTopicId">
        </app-topic-phrases>
      </mat-tab>

      <!-- Tab Sentence Generator -->
      <mat-tab label="Sentence Generator">
        <mat-accordion *ngIf="activeTab === 1">
          <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sentence Generator
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-sentences
              [topic]="selectedTopicId"
              [editSentence]="appPreview.statementToEdit">
            </app-sentences>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sentences Preview
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-preview-sentences
              #appPreview
              (editStatement)="editStatement()"
              [topic]="selectedTopicId"
              [published]="false">
            </app-preview-sentences>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-tab>

      <!-- Tab  Published phrases -->
      <mat-tab label="Published phrases">
        <app-preview-phrases
          *ngIf="activeTab === 2"
          [topic]="selectedTopicId">
        </app-preview-phrases>
      </mat-tab>

      <!-- Tab Published sentences -->
      <mat-tab label="Published sentences">
        <app-preview-sentences
          [topic]="selectedTopicId"
          [published]="true">
        </app-preview-sentences>
      </mat-tab>

    </mat-tab-group>
  </ng-container>
</div>
