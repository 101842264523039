import {DragDropModule} from '@angular/cdk/drag-drop';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getDatabase, provideDatabase} from '@angular/fire/database';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreviewPhrasesComponent} from './components/preview-phrases/preview-phrases.component';
import {PreviewComponent} from './components/preview/preview.component';
import {SentencesComponent} from './components/sentences/sentences.component';
import {StatementEditComponent} from './components/sentences/statement-edit/statement-edit.component';
import {TopicListComponent} from './components/topic-list/topic-list.component';
import {TopicPhraseAdjectivesComponent} from './components/topic-phrase-adjectives/topic-phrase-adjectives.component';
import {TopicPhraseFormsComponent} from './components/topic-phrase-forms/topic-phrase-forms.component';
import {TopicPhrasesComponent} from './components/topic-phrases/topic-phrases.component';
import {TopicSettingComponent} from './components/topic-settings/topic-settings.component';
import {FirebaseService} from './firebase.service';
import {GeneratorService} from './generator.service';
import {MaterialModule} from './material.module';
import {HomeComponent} from './pages/home/home.component';
import {TopicNewPageComponent} from './pages/topic/topic-new.component';
import {TopicPageComponent} from './pages/topic/topic.component';
import {TopicsPageComponent} from './pages/topic/topics-wrapper.component';
import {StorageService} from './storage.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopicsPageComponent,
    TopicPageComponent,
    TopicSettingComponent,
    TopicPhrasesComponent,
    TopicPhraseFormsComponent,
    TopicPhraseAdjectivesComponent,
    PreviewComponent,
    PreviewPhrasesComponent,
    SentencesComponent,
    StatementEditComponent,
    TopicListComponent,
    TopicNewPageComponent,
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatSnackBarModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseApi)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
  ],
  providers: [
    FirebaseService,
    StorageService,
    GeneratorService,
    MatSnackBar,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
